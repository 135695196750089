import { Component, OnInit } from '@angular/core';
import { StoreDataService } from '@common/services/store-data.service';
import { ActivatedRoute } from '@angular/router';

import { Profile } from '@common/models/profile.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    locationName = '';
    locationPhone = '';

    constructor (
        private storeDataService: StoreDataService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (Object.keys(params).length > 0 && (params['locationName'] || params['state'] || params['locationPhone'])) {
                params['locationName'] ? this.locationName = params['locationName'] : this.locationName = '';
                params['locationPhone'] ? this.locationPhone = this.phoneFormatter(params['locationPhone']) : this.locationPhone = '';
            } else {
                this.storeDataService.profileObserver().subscribe((profile: Profile) => {
                    this.locationName = profile.locationName || '';
                    if (profile.locationPhone) {
                        this.locationPhone = this.phoneFormatter(profile.locationPhone);
                    }
                })
            }
        });
    }

    phoneFormatter(phone: string): string {
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    }
}
