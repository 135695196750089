import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
    private dialogState = new Subject<any>();
    private dialogClose = new Subject<any>();

    dialogState$ = this.dialogState.asObservable();
    dialogClose$ = this.dialogClose.asObservable();

    openDialog(data?: any): Observable<any> {
        this.dialogState.next({ isOpen: true, data });
        return this.dialogClose$;
    }

    closeDialog(result: any) {
        this.dialogState.next({ isOpen: false, result });
        this.dialogClose.next(result);
    }
}
