import { ApplianceObj, RoomObj } from '@common/models/rooms.model';

export const ALL_ROOMS_OBJ: { [key: string]: RoomObj } = {
    'Dining Room': {
        imageSrc: '../../../assets/images/dining-area-icon.svg',
        activeImageSrc: '../../../assets/images/dining-area-active-icon.svg',
        roomName: 'Dining area',
    },
    'Office/Library': {
        imageSrc: '../../../assets/images/office-icon.svg',
        activeImageSrc: '../../../assets/images/office-active-icon.svg',
        roomName: 'Office or library',
    },
    'Other': {
        imageSrc: '../../../assets/images/playroom-icon.svg',
        activeImageSrc: '../../../assets/images/playroom-active-icon.svg',
        roomName: 'Playroom',
    },
    'Living Room': {
        imageSrc: '../../../assets/images/living-room-icon.svg',
        activeImageSrc: '../../../assets/images/living-room-active-icon.svg',
        roomName: 'Living room',
    },
    'Kitchen': {
        imageSrc: '../../../assets/images/kitchen-icon.svg',
        activeImageSrc: '../../../assets/images/kitchen-active-icon.svg',
        roomName: 'Kitchen',
    },
    'Utility Room/Laundry Room': {
        imageSrc: '../../../assets/images/laundry-room-icon.svg',
        activeImageSrc: '../../../assets/images/laundry-room-active-icon.svg',
        roomName: 'Laundry room',
    },
    'Family Room': {
        imageSrc: '../../../assets/images/family-room-icon.svg',
        activeImageSrc: '../../../assets/images/family-room-active-icon.svg',
        roomName: 'Family room',
    },
    'Foyer/Other Entry': {
        imageSrc: '../../../assets/images/family-room-icon.svg',
        activeImageSrc: '../../../assets/images/family-room-active-icon.svg',
        roomName: 'Entry',
    },
    'Mud Room': {
        imageSrc: '../../../assets/images/mud-room-icon.svg',
        activeImageSrc: '../../../assets/images/mud-room-active-icon.svg',
        roomName: 'Mud room',
    },
    'Sun Room': {
        imageSrc: '../../../assets/images/sun-room-icon.svg',
        activeImageSrc: '../../../assets/images/sun-room-active-icon.svg',
        roomName: 'Sun room',
    },
    'Rec Room': {
        imageSrc: '../../../assets/images/rec-room-icon.svg',
        activeImageSrc: '../../../assets/images/rec-room-active-icon.svg',
        roomName: 'Rec room',
    },
    'Bedroom': {
        imageSrc: '../../../assets/images/bedroom-icon.svg',
        activeImageSrc: '../../../assets/images/bedroom-active-icon.svg',
        roomName: 'Bedroom',
    },
    'Bathroom': {
        imageSrc: '../../../assets/images/fullbath-icon.svg',
        activeImageSrc: '../../../assets/images/fullbath-active-icon.svg',
        roomName: 'Full bath',
    },
    'Half Bath':{
        imageSrc: '../../../assets/images/halfbath-icon.svg',
        activeImageSrc: '../../../assets/images/halfbath-active-icon.svg',
        roomName: 'Half bath',
    },
};

export const APPLIANCES_OBJ: { [key: string]: ApplianceObj } =  {
    'Refrigerator': {
        imageSrc: '../../../assets/images/fridge-icon.svg',
        name: 'Fridge clean',
        quantity: 1,
    },
    'Oven': {
        imageSrc: '../../../assets/images/oven-icon.svg',
        name: 'Oven clean',
        quantity: 1,
    },
};

export type AdditionalRoomNames = 'bedroom' | 'bathroom' | 'half bath';
