import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { phoneLengthValidator } from '@common/validators/formValidators';
import { PickerData } from '@common/models/picker-option.model';
import { COMMON_URLS } from '@constants/common-urls.constants';
import { CONTACT_PREFERENCES } from '@constants/contact-preferences.constants';

@Component({
    selector: 'app-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit, OnChanges {
    @Input() isOpen = false;
    @Input() data: any = {};
    @Output() closeDialog = new EventEmitter<any>();
    contactData: Array<PickerData> = [
        { label: 'Call', value: CONTACT_PREFERENCES.PHONE },
        { label: 'Text', value: CONTACT_PREFERENCES.TEXT },
    ];
    contactForm: FormGroup = new FormGroup({});
    selectedContactValue = CONTACT_PREFERENCES.PHONE;
    commonUrls = COMMON_URLS;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.buildContactForm();
    }

    buildContactForm(): void {
        this.contactForm = this.formBuilder.group({
            phone: new FormControl(null, [
                Validators.required,
                phoneLengthValidator(10, 10),
                Validators.pattern('^[0-9 ]*$'),
            ]),
            terms: new FormControl(false, Validators.requiredTrue),
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && this.data?.phoneNumber) {
            this.contactForm.get('phone')?.setValue(this.data.phoneNumber);
        }
    }

    onContactSelected(value: string | number) {
        this.selectedContactValue = value as string;
    }

    onClose(): void {
        this.isOpen = false;
        this.closeDialog.emit({ result: null });
    }

    onSave() {
        this.isOpen = false;
        this.closeDialog.emit({ result: {
            phone: this.contactForm.get('phone')?.value,
            contactPreference: this.selectedContactValue
        } });
    }

    onSubmit() {}
}
