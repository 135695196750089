import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreDataService } from '@common/services/store-data.service';
import { BaseStepComponent } from '@components/booking-steps/base-step-component';
import { COMMON_URLS } from '@constants/common-urls.constants';


@Component({
    selector: 'app-booking-completed',
    templateUrl: './booking-completed.component.html',
    styleUrls: ['../../../assets/scss/special-page-styles.scss']
})
export class BookingCompletedComponent  extends BaseStepComponent implements OnInit, OnDestroy {
    override progressBarStatus = {
        progress: 0,
        completedStep: 0,
        isProgressBarShown: false
    };
    commonUrls = COMMON_URLS;
    localSiteUrl = this.commonUrls.LOCAL_SITE;
    override disableNavigation = true;

    constructor (
        private storeDataService: StoreDataService,
    ) {
        super()
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.localSiteUrl = this.storeDataService.profileData.localSiteUrl || this.localSiteUrl;
        this.navigationService.setNextNavigationShown(false);
        this.navigationService.setPrevNavigationShown(false);
        this.progressBarService.changeProgressBarStatus(this.progressBarStatus);
    }
}
