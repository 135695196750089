<footer>
    <div class="navigation-holder">
        <strong class="brand-title">A <span>ServiceMaster</span> Company®</strong>
        <h5 class="mobile">{{contentData.infoText}}</h5>
        <nav>
            <ul>
                <li>
                    <a gaEvent="click" gaCategory="footer_link" gaLabel="Terms of Use" [href]="contentData.termsOfUse" target="_blank">Terms of Use</a>
                </li>
                <li>
                    <a gaEvent="click" gaCategory="footer_link" gaLabel="Privacy Policy" [href]="contentData.privacePolicy" target="_blank">Privacy Policy</a>
                </li>
                <li>
                    <a gaEvent="click" gaCategory="footer_link" gaLabel="Don’t Share/Sell My Data" [href]="contentData.doNotShare" target="_blank">Don’t Share/Sell My Data</a>
                </li>
                <li>
                    <a gaEvent="click" gaCategory="footer_link" gaLabel="Accessibility" [href]="contentData.accessibility" target="_blank">Accessibility</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="social-holder">
        <h5>{{contentData.infoText}}</h5>
        <ul class="social">
            <li>
                <a gaEvent="click" gaCategory="footer_link" gaLabel="Facebook" [href]="contentData.facebook" target="_blank">
                    <img src="assets/icons/facebook-icon.svg" width="17" height="32" alt="Facebook">
                </a>
            </li>
            <li>
                <a gaEvent="click" gaCategory="footer_link" gaLabel="Instagram" [href]="contentData.instagram" target="_blank">
                    <img src="assets/icons/instagram-icon.svg" width="34" height="34" alt="Instagram">
                </a>
            </li>
            <li>
                <a gaEvent="click" gaCategory="footer_link" gaLabel="Youtube" [href]="contentData.youtube" target="_blank">
                    <img src="assets/icons/youtube-icon.png" width="26" height="30" alt="Youtube">
                </a>
            </li>
        </ul>
    </div>
</footer>
