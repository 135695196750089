import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProgressBarService } from '@components/progress-bar/progress-bar.service';
import { ProgressBarState } from '@common/models/navigation.model';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    progressStatus!: ProgressBarState;
    progressBarSubscription!: Subscription;
    progressLines = [1,2,3];
    progressSteps = [
        'Your Clean',
        'Quote',
        'Summary'
    ];
    isProgressBarShown: boolean | undefined = true;

    constructor(private progressBarService: ProgressBarService) { }

    ngOnInit(): void {
        this.progressBarSubscription = this.progressBarService.getChangedProgressBarStatus()
            .subscribe((value: ProgressBarState) => {
                this.progressStatus = value;
                this.isProgressBarShown = !value?.isProgressBarShown && value?.isProgressBarShown !== undefined ? false : true;
            });
    }

    ngOnDestroy(): void {
        this.progressBarSubscription.unsubscribe();
    }
}
