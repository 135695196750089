import { CleanType } from '@common/models/clean-type.model';

export const CLEANING_FREQUENCY = {
  ONE_TIME: 'OneTime',
  WEEKLY: 'weekly',
  BI_WEEKLY: 'x2week',
  MONTHLY: 'monthly',
}

export const OCCASIONS = {
  MOVING_IN: 'moving-in',
  MOVING_OUT: 'moving-out',
  SPECIAL: 'special-occasion',
  OTHER: 'something-else'
}

export const CLEAN_TYPES: Array<CleanType> = [
    {
        imageSrc: '../../../assets/images/kitchen-icon.svg',
        title: 'Kitchen',
        listEssential: ['Clean countertops, tables, and chairs', 'Clean major appliance exteriors', 'Clean, scrub, and sanitize sinks, countertops, and backsplashes', 'Clean inside and outside of microwave', 'Vacuum and wash floor'],
        listSparkle: ['Wet wipe all cabinet fronts, tables, and chairs', 'Wet wipe all trimwork'],
    },
    {
        imageSrc: '../../../assets/images/halfbath-icon.svg',
        title: 'Bathroom',
        listEssential: ['Clean, scrub, and sanitize showers, bathtubs, sinks and toilets', 'Clean mirrors', 'Vacuum and wash floor'],
        listSparkle: ['Wet wipe cabinet fronts and tile walls', 'Wet wipe all trimwork', 'Clean blinds and shutters'],
    },
    {
        imageSrc: '../../../assets/images/living-room-icon.svg',
        title: 'All Rooms',
        listEssential: ['Dust trimwork, doors, blinds and shutters, furniture, ceiling fans, light fixtures, and decor', 'Vacuum and wash all hard surface flooring', 'Vacuum carpets', 'Vacuum upholstery', 'Empty wastebaskets', 'Dust light switch plates and vents'],
        listSparkle: ['Wet wipe all trimwork, light fixtures, and ceiling fans', 'Clean blinds and shutters', 'Wet wipe light switch plates and vents'],
    }
];
