import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConversionTrackingService {
  gtag_report_conversion(url?: string): void {
    const callback = () => {
        console.log('!!!')
    //   if (url) {
    //     // Use window.location.href to change the location
    //     window.location.href = url;
    //   }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-11203472226/E1cjCKGJvr8ZEOLWnd4p',
      'event_callback': callback
    });
  }
}
