import { Component, Input, EventEmitter, Output, signal, computed, Signal } from '@angular/core';
import { PickerData } from '@common/models/picker-option.model';

@Component({
    selector: 'app-picker',
    templateUrl: './picker.component.html',
    styleUrls: ['./picker.component.scss'],
})
export class PickerComponent {
    _pickerData = signal<PickerData[]>([]);
    _selectedValue = signal<string | number | null>(null);

    @Input()
    set pickerData(data: PickerData[]) {
        this._pickerData.set(data);
    }
    @Input()
    set selectedValue(value: string | number) {
        this._selectedValue.set(value);
    }

    @Output() optionSelected = new EventEmitter<number | string>();

    selectOption(value: number | string) {
        this._selectedValue.set(value);
        this.optionSelected.emit(value);
    }
    selectedOption: Signal<PickerData | undefined> = computed(() => {
        const options = this._pickerData();
        const selectedOption = this._selectedValue();
        return options.find((option) => option.value === selectedOption);
    });
}
