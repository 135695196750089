import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-expandable-list',
    templateUrl: './expandable-list.component.html',
    styleUrl: './expandable-list.component.scss',
    animations: [
        trigger('expandCollapse', [
            state('collapsed', style({
                height: '0px',
                opacity: 0
            })),
            state('expanded', style({
                height: '*',
                opacity: 1
            })),
            transition('collapsed <=> expanded', [
                animate('300ms ease-in-out')
            ]),
        ])
    ]
})
export class ExpandableListComponent {
    @Input() items: string[] = [];
    @Input() defaultVisibleCount: number = 3;

    isExpanded: boolean = false;

    get isExpandable(): boolean {
        return this.items.length > this.defaultVisibleCount;
    }

    get alwaysVisibleItems(): string[] {
        return this.items.slice(0, this.defaultVisibleCount);
    }

    get expandedItems(): string[] {
        return this.isExpanded ? this.items.slice(this.defaultVisibleCount + 1, this.items.length) : [];
    }

    toggleExpansion(event: Event): void {
        event.preventDefault();
        this.isExpanded = !this.isExpanded;
    }
}
