import { Component } from '@angular/core';
import { COMMON_URLS } from '@constants/common-urls.constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    contentData = {
        infoText: 'Merry Maids services provided by independently owned and operated Franchises or by a corporate owned branch. Prices and services vary by location',
        termsOfUse: COMMON_URLS.TERMS_OF_USE,
        privacePolicy: COMMON_URLS.PRIVACY_POLICY,
        doNotShare: COMMON_URLS.DO_NOT_SHARE,
        accessibility: COMMON_URLS.ACCESSIBILITY,
        facebook: COMMON_URLS.FACEBOOK,
        instagram: COMMON_URLS.INSTAGRAM,
        youtube: COMMON_URLS.YOUTUBE
    }
}
