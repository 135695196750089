import { Component, OnInit } from '@angular/core';

import { ProgressBarService } from '@components/progress-bar/progress-bar.service';
import { Title } from '@angular/platform-browser';
import { PAGES, PAGE_TITLES } from '@constants/pages-config.constants';

@Component({
    selector: 'app-error-404',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    progressBarStatus = null;

    constructor (
        private progressBarService: ProgressBarService,
        private pageTitle: Title
    ) { }

    ngOnInit(): void {
        this.changeProgressBarStatus();
        this.pageTitle.setTitle(PAGE_TITLES[PAGES.NOT_FOUND]);
    }

    changeProgressBarStatus(): void {
        this.progressBarService.changeProgressBarStatus(this.progressBarStatus);
    }
}
