import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '@constants/endpoints.constants';
import { Observable, map, of } from 'rxjs';
import { RoomTypesParams, Appliance, Room } from '@common/models/rooms.model';
import { Price } from '@common/models/price.model';
import { QuoteRate } from '@common/models/quote-rate.model';
import { Profile } from '@common/models/profile.model';

@Injectable({
    providedIn: 'root',
})
export class PricingQuoteService {
    otherRooms: Room[] = [];
    appliances: Appliance[] = [];

    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) {}

    getPricingQuote(requestData: Profile) {
        return this.http.post<Price>(`${API_BASE_URL}/quote/${requestData.locationCode}/calculate`, requestData);
    }

    getQuoteRate(locationCode: number | null) {
        return this.http.get<QuoteRate>(`${API_BASE_URL}/quote/${locationCode}`);
    }

    addRoomType(roomName: string) {
        return this.http.get<any>(`${API_BASE_URL}/rooms/4389/single?roomType=${roomName}`);
    }

    getRoomTypes(locationCode: number | null, params: RoomTypesParams) {
        const queryMap: { [key: string]: string } = {
            levels: 'levels',
            numberOfBedrooms: 'bedrooms',
            numberOfBathrooms: 'bathrooms',
            numberOfHalfbathrooms: 'halfbathrooms',
        };
        let query = '';
        const entries = Object.entries(params);

        for (const [key, value] of entries) {
            if (typeof value === 'number' || typeof value === 'string') {
                if (query.length === 0) {
                    query += `${queryMap[key]}=${value}`;
                } else {
                    query += `&${queryMap[key]}=${value}`;
                }
            }
        }

        return this.http.get<Room[]>(`${API_BASE_URL}/rooms/${locationCode}?${query}`);
    }

    getAppliances(locationCode: number | null | undefined) {
        if (this.appliances.length > 0) {
            return of(this.appliances);
        } else {
            return this.http
                .get<Appliance[]>(`${API_BASE_URL}/appliances/${locationCode}`)
                .pipe(map((appliances) => (this.appliances = appliances)));
        }
    }
}
