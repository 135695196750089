<div class="info-container">
    <div class="info-area">
        <img
            src="../../../assets/images/placeholder-img.svg"
            width="394px"
            height="300px"
            class="placeholder-image"
            alt="placeholder image"
        />
        <h1 class="title">Thank you for choosing Merry Maids for your cleaning needs!</h1>
        <p class="text-info">We have received your request for cleaning and will contact you shortly to schedule. We can't wait to make your home sparkle!</p>
    </div>
    <a [href]="localSiteUrl" class="link-back">Back to main site</a>
</div>
