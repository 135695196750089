import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ALLOWED_EMAIL_DOMAINS } from '@constants/domains.constants';

export const zeroValueValidator = (control: AbstractControl): { [key: string]: any } | null => {
  const value = control.value;
  const pattern = /^(?!0+$).*|^$/;
  if (!pattern.test(value)) {
    return { notEqualToZero: true };
  }
  return null;
}

export const maxSquareFeetValidator = (max: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value !== null && value !== undefined) {
      const trimmedValue = value.toString().replace(/\s/g, '');
      if (!trimmedValue || /^\s*$/.test(trimmedValue)) {
        return { pattern: true };
      }

      const numericValue = parseFloat(trimmedValue);
      if (!isNaN(numericValue) && numericValue > max) {
        return { max: true };
      }
    }

    return null;
  };
}

export const atLeastOneCheckedValidator = (control: AbstractControl): ValidationErrors | null => {
  const atLeastOneChecked = Object.keys(control.value).some(key => control.value[key]);
  return atLeastOneChecked ? null : { noSelection: true };
};

export const phoneLengthValidator = (minLength: number, maxLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber = control.value ? control.value.replace(/\s/g, '') : '';
    const length = phoneNumber.length;

    if (length < minLength) {
      return { minlength: true };
    }

    if (length > maxLength) {
      return { maxlength: true };
    }

    return null;
  };
}

export const domainValidator = (control: AbstractControl): ValidationErrors | null  => {
    const email: string = control.value;
    const domain = email?.substring(email?.lastIndexOf('.') + 1);
    if (ALLOWED_EMAIL_DOMAINS.includes(domain?.toUpperCase())) {
      return null;
    } else {
      return { invalidDomain: true };
    }
};
