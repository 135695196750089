<div class="slider-holder">
    <button [disabled]="value && options.floor && (value <= options.floor)" class="slider-btn" (click)="decreaseValue()">-</button>
    <ngx-slider
        [ngClass]="{ pressed: isSliderPressed }"
        (userChangeStart)="onUserChangeStart()"
        (userChangeEnd)="onUserChangeEnd(value)"
        [(value)]="value"
        [options]="options"
    ></ngx-slider>
    <button [disabled]="value && options.ceil && (value >= options.ceil)" class="slider-btn" (click)="increaseValue()">+</button>
</div>
