<div class="stepper-holder">
    <button [class.disabled]="value === min" (click)="decrement()">-</button>
    <input
        class="input-field"
        type="number"
        [value]="value"
        (blur)="onInputBlur($event)"
        (input)="onInputChange($event)"
    />
    <button [class.disabled]="value === max" (click)="increment()">+</button>
</div>
