import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile } from '@common/models/profile.model';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '@constants/endpoints.constants';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { PAGES_URLS, PAGES } from '@constants/pages-config.constants';
import { SelectedAppliance, Room } from '@common/models/rooms.model';

@Injectable({
    providedIn: 'root',
})
export class StoreDataService {
    public locationCode = null;
    private profile: BehaviorSubject<Profile> = new BehaviorSubject<Profile>({
        //profileId: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        suite: null,
        email: null,
        phone: null,
        locationPhone: null,
        locationEmail: null,
        locationName: null,
        postalCode: null,
        updatedAt: null,
        createdAt: null,
        contactPreference: null,
        cleaningOccasion: null,
        lastCleaned: null,
        initialCleaningFrequency: null,
        cleaningFrequency: null,
        square: null,
        url: null,
        locationCode: null,
        petsNumber: null,
        levels: null,
        numberOfBedrooms: null,
        numberOfBathrooms: null,
        numberOfHalfbathrooms: null,
        rooms: null,
        otherRooms: null,
        subscriptionType: null,
        subscriptionOn: false,
        concerns: null,
        appliances: null,
        scheduling: null,
        termsAccepted: false,
        marketingConsent: false,
        switchedToRecurring: false,
        cancellationFeesAccepted: false,
        firstCleanPrice: null,
        recurringCleanPrice: null,
        localSiteUrl: null,
    });
    routeToContinueBooking = new Subject<string | undefined>();
    appStarted = false;
    params: any;
    userInfo: Profile = {};
    continueBooking = false;
    initCompleted = false;
    validationPropertyExceptions: string[] = [
        'cleaningOccasion',
        'appliances',
        // 'numberOfBedrooms',
        // 'numberOfBathrooms',
        // 'numberOfHalfbathrooms',
    ];

    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) {}

    get profileData(): Profile {
        return this.profile.value;
    }
    set profileData(data: Profile) {
        this.profile.next({
            ...this.profile.value,
            ...data,
        });
    }
    public profileObserver(): Observable<Profile> {
        return this.profile.asObservable();
    }
    setupInitialData() {
        this.route.queryParams.subscribe((params) => {
            if (Object.keys(params).length > 0) {
                this.params = params;
                if (params['continueBooking']) {
                    this.continueBooking = !!params['continueBooking'];
                }
                if (!this.initCompleted) {
                    this.initCompleted = true;
                    if (params['profileId']) {
                        this.getProfile(params['profileId']);
                    } else {
                        this.storeInitialData();
                    }
                }

                this.locationCode = params['locationCode'];
            }
        });
    }

    storeInitialData() {
        const userInfo = this.retrieveUserInfo();
        this.storeCurrentData(userInfo).subscribe((res: Profile) => {
            const routerLink = {
                queryParams: {
                    profileId: res.profileId,
                    firstName: null,
                    lastName: null,
                    address: null,
                    city: null,
                    state: null,
                    suite: null,
                    email: null,
                    phone: null,
                    postalCode: null,
                    locationPhone: null,
                    locationEmail: null,
                    locationName: null,
                    localSiteUrl: null,
                },
            };
            this.router.navigate([PAGES_URLS[PAGES.YOUR_CLEAN]], {
                queryParams: routerLink.queryParams,
                queryParamsHandling: 'merge',
            });
        });
    }

    retrieveUserInfo(): Profile {
        this.userInfo = {
            address: this.params['address'] || this.profileData['address'],
            localSiteUrl: this.params['localSiteUrl'] || this.profileData['localSiteUrl'],
            city: this.params['city'] || this.profileData['city'],
            state: this.params['state'] || this.profileData['state'],
            suite: this.params['suite'] || this.profileData['suite'],
            postalCode: this.params['postalCode'] || this.profileData['postalCode'],
            locationCode: this.params['locationCode'] || this.profileData['locationCode'],
            locationPhone: this.params['locationPhone'] || this.profileData['locationPhone'],
            locationName: this.params['locationName'] || this.profileData['locationName'],
            locationEmail: this.params['locationEmail'] || this.profileData['locationEmail'],
            serviceTerritoryID: this.params['serviceTerritoryID'] || this.profileData['serviceTerritoryID'],
        };

        return this.userInfo;
    }

    getProfile(profileId: string | null) {
        this.http.get<Profile>(`${API_BASE_URL}/customer/${profileId}`).subscribe({
            next: (profile: Profile) => {
                if (!this.appStarted) {
                    this.profileData = profile;
                    this.appStarted = true;
                    if (this.continueBooking) {
                        if (profile.url) this.routeToContinueBooking.next(profile.url);
                        this.continueBooking = false;
                    }
                }
            },
            error: (e) => {
                console.error(e);
            },
            complete: () => {
                console.info('complete');
            },
        });
    }

    storeCurrentData(profileData: Profile, resetNextStepsData?: boolean) {
        if (resetNextStepsData) {
            this.getCleanedDataAfterFlowChange();
        }
        const body = {
            ...this.profileData,
            ...profileData,
        };
        return this.http.put<Profile>(`${API_BASE_URL}/customer`, body).pipe(
            map((profile: Profile) => {
                this.profileData = profile;
                return this.profileData;
            })
        );
    }

    getCleanedDataAfterFlowChange() {
        this.profileData = {
            ...this.profileData,
            ...{
                cleaningOccasion: null,
                lastCleaned: null,
                cleaningFrequency: null,
                square: null,
                url: null,
                petsNumber: null,
                levels: null,
                numberOfBedrooms: null,
                numberOfBathrooms: null,
                numberOfHalfbathrooms: null,
                otherRooms: null,
                rooms: null,
                concerns: null,
                scheduling: null,
                switchedToRecurring: false,
                appliances: null,
                subscriptionType: null,
                subscriptionOn: false,
            },
        };
    }

    // getProfileDataForQuoteConfirm(): { bodyRequest: Profile; valid: boolean } {
    //     const bodyRequest = {
    //         locationCode: this.locationCode,
    //         square: this.profileData.square,
    //         numberOfPets: this.profileData.petsNumber,
    //         rooms:
    //             this.profileData.otherRooms?.length && this.profileData.rooms
    //                 ? [
    //                       ...this.profileData.rooms,
    //                       ...this.profileData.otherRooms,
    //                   ]
    //                 : this.profileData.rooms,
    //         appliances: this.profileData.appliances,
    //     };

    //     const valid = this.isProfileDataValidForQuote(bodyRequest);
    //     return { bodyRequest, valid };
    // }

    getProfileDataForQuote(
        selectedRooms: Room[],
        appliances: SelectedAppliance[]
    ): { bodyRequest: Profile; valid: boolean } {
        const bodyRequest = {
            locationCode: this.locationCode,
            square: this.profileData.square,
            numberOfPets: this.profileData.petsNumber,
            rooms: selectedRooms,
            appliances: appliances,
        };
        const valid = this.isProfileDataValidForQuote(bodyRequest);
        return { bodyRequest, valid };
    }

    isProfileDataValidForQuote(profile: any) {
        for (const key in profile) {
            if (Object.prototype.hasOwnProperty.call(profile, key)) {
                if (this.validationPropertyExceptions.includes(key)) {
                    continue;
                }
                if (profile[key] === null) {
                    return false;
                }
            }
        }
        return true;
    }

    sendOrder(orderParameter?: '4000' | 'CustomizePrice') {
        return this.http.post<Profile>(
            `${API_BASE_URL}/lead/${this.profileData.profileId}/place?managerRequest=${orderParameter ? orderParameter : ''}`,
            {}
        );
    }
}
