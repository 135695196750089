import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-detailed-dialog',
    templateUrl: './detailed-dialog.component.html',
    styleUrls: ['./detailed-dialog.component.scss'],
})
export class DetailedDialogComponent {
    @Input() isOpen = false;
    @Input() data: any;
    @Output() closeDialog = new EventEmitter<any>();

    onClose(): void {
        this.isOpen = false;
        this.closeDialog.emit({ result: null });
    }

    toggleDetailedMode() {
        this.data.isDetailedMode = !this.data.isDetailedMode;
        this.isOpen = false;
        this.closeDialog.emit({ result: this.data.isDetailedMode });
    }
}
