<h1>Merry Maids {{ city }} <br />Welcomes You!</h1>
<form class="contact-form" [formGroup]="contactsForm" (focusout)="onContactsFormFocusOut()">
    <div class="row">
        <div class="input-holder">
            <label
                [ngClass]="{
                    'invalid-field':
                        contactsForm.controls['firstName'].touched && contactsForm.controls['firstName'].invalid
                }"
            >
                <span class="label">First Name</span>
                <input
                    type="text"
                    class="form-control st-input-field"
                    formControlName="firstName"
                    (blur)="firstNameOnBlur()"
                    required
                    [ngClass]="{
                        'warning-input':
                            contactsForm.controls['firstName'].touched && contactsForm.controls['firstName'].invalid
                    }"
                />
                <ng-container
                    *ngIf="contactsForm.controls['firstName'].touched && contactsForm.controls['firstName'].invalid"
                >
                    <span class="warning-text" *ngIf="!contactsForm.controls['firstName'].value"
                        >Please fill in this field to proceed</span
                    >
                    <span
                        class="warning-text"
                        *ngIf="
                            contactsForm.get('firstName')?.hasError('pattern') ||
                            contactsForm.controls['firstName']?.hasError('maxlength')
                        "
                        >Text length should be from 1 to 35 symbols. Symbols "-", apostrophe, and space are
                        allowed</span
                    >
                </ng-container>
            </label>
        </div>
        <div class="input-holder">
            <label
                [ngClass]="{
                    'invalid-field':
                        contactsForm.controls['lastName'].touched && contactsForm.controls['lastName'].invalid
                }"
            >
                <span class="label">Last name</span>
                <input
                    type="text"
                    class="form-control st-input-field"
                    formControlName="lastName"
                    required
                    [ngClass]="{
                        'warning-input':
                            contactsForm.controls['lastName'].touched && contactsForm.controls['lastName'].invalid
                    }"
                    (blur)="lastNameOnBlur()"
                />
                <ng-container
                    *ngIf="contactsForm.controls['lastName'].touched && contactsForm.controls['lastName'].invalid"
                >
                    <span class="warning-text" *ngIf="!contactsForm.controls['lastName'].value"
                        >Please fill in this field to proceed</span
                    >
                    <span
                        class="warning-text"
                        *ngIf="
                            contactsForm.get('lastName')?.hasError('pattern') ||
                            contactsForm.get('lastName')?.hasError('maxlength')
                        "
                        >Text length should be from 1 to 35 symbols. Symbols "-", apostrophe, and space are
                        allowed</span
                    >
                </ng-container>
            </label>
        </div>
    </div>
    <div class="row">
        <div class="input-holder">
            <label
                [ngClass]="{
                    'invalid-field': contactsForm.controls['email'].touched && !contactsForm.controls['email'].valid
                }"
            >
                <span class="label">Email</span>
                <input
                    type="email"
                    class="form-control st-input-field"
                    formControlName="email"
                    required
                    [ngClass]="{
                        'warning-input': contactsForm.controls['email'].touched && !contactsForm.controls['email'].valid
                    }"
                    (blur)="emailOnBlur()"
                />
                <ng-container *ngIf="contactsForm.controls['email'].touched && !contactsForm.controls['email'].valid">
                    <span class="warning-text" *ngIf="!contactsForm.controls['email'].value"
                        >Please fill in this field to proceed</span
                    >
                    <span class="warning-text" *ngIf="contactsForm.get('email')?.hasError('pattern')"
                        >Email address could contain Latin characters and special characters "-", "_", ".". No
                        spaces.</span
                    >
                    <span class="warning-text" *ngIf="contactsForm.get('email')?.hasError('maxlength')"
                        >Email address should not exceed 250 characters.</span
                    >
                    <span
                        class="warning-text"
                        *ngIf="
                            contactsForm.get('email')?.hasError('invalidDomain') &&
                            !contactsForm.get('email')?.hasError('pattern') &&
                            contactsForm.controls['email'].value
                        "
                        >Please enter a valid email address.</span
                    >
                </ng-container>
            </label>
        </div>
        <div class="input-holder">
            <label
                [ngClass]="{
                    'invalid-field': contactsForm.controls['phone'].touched && !contactsForm.controls['phone'].valid
                }"
            >
                <span class="label">Phone number</span>
                <input
                    type="text"
                    class="form-control st-input-field"
                    formControlName="phone"
                    required
                    [ngClass]="{
                        'warning-input': contactsForm.controls['phone'].touched && !contactsForm.controls['phone'].valid
                    }"
                    (blur)="phoneOnBlur()"
                />
                <ng-container *ngIf="contactsForm.controls['phone'].touched && !contactsForm.controls['phone'].valid">
                    <span class="warning-text" *ngIf="!contactsForm.controls['phone'].value"
                        >Please fill in this field to proceed</span
                    >
                    <span class="warning-text" *ngIf="contactsForm.get('phone')?.hasError('pattern')"
                        >Only digits are allowed</span
                    >
                    <span class="warning-text" *ngIf="contactsForm.get('phone')?.hasError('minlength')"
                        >Phone number can't include less than 10 numbers</span
                    >
                    <span class="warning-text" *ngIf="contactsForm.get('phone')?.hasError('maxlength')"
                        >Phone number can't include more than 10 numbers</span
                    >
                </ng-container>
            </label>
        </div>
    </div>
    <div class="row">
        <span class="label">Cleaning address</span>
        <div class="address-block">
            <a
                class="link"
                gaEvent="click"
                gaCategory="incorrect_address"
                gaLabel="This isn’t the right address"
                (click)="trackConversion(localSiteUrl)"
                [href]="localSiteUrl"
            >
                Edit
            </a>
            <span class="address-text" *ngIf="addressLoaded">{{ address }}</span>
        </div>
    </div>
</form>
<div class="tabs-block">
    <h2>To get the most accurate estimate for your cleaning, we will collect some important information.</h2>
    <h3>How many levels is your home?</h3>
    <app-picker
        [pickerData]="levelsData"
        [selectedValue]="levelsNumber"
        (optionSelected)="onLevelsSelected($event)"
    ></app-picker>
</div>
<div class="footage-block">
    <div class="heading">
        <h3>Approximate Square Footage</h3>
    </div>
    <app-slider
        [options]="footageOptions"
        [formattedSliderValue]="footageStringValue"
        [value]="footageDefaultValue"
        (valueChanged)="onFootageValueChanged($event)"
    ></app-slider>
    <div class="footage-limit">
        <div class="btn-holder button-tooltip">
            <button [disabled]="!contactsForm.valid" (click)="sendManagerRequest()" class="action-btn">
                <img src="../../../assets/images/star-icon.svg" width="16px" height="16px" alt="star icon" />
                Over 4,000 sq ft
            </button>
            <span class="tooltip" *ngIf="!contactsForm.valid">Please enter your personal data to submit</span>
        </div>
        <p>
            If your home is over 4,000 sq ft, we require some additional info for precise pricing. Our team will contact
            you soon!
        </p>
    </div>
    <span class="disabled-text" *ngIf="!contactsForm.valid"
        >Personal data is required to submit homes over 4000ft²</span
    >
</div>
<div class="pets-block">
    <div class="heading">
        <h3>We love caring for all of your family!</h3>
        <p>How many furry or feathered family members live in your home?</p>
    </div>
    <app-stepper-input
        class="stepper-input"
        [step]="petsStep"
        [min]="petsMinValue"
        [max]="petsMaxValue"
        [initialValue]="petsInitialValue"
        gaLabel="Pets"
        (valueChange)="onPetsValueChange($event)"
    >
    </app-stepper-input>
</div>
