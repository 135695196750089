<div class="dialog-wrapper" *ngIf="isOpen">
    <section class="dialog">
        <button class="dialog-close" (click)="onClose()">Close</button>
        <span class="dialog-title"
            >Our team will contact you soon to customize your clean!</span
        >
        <form [formGroup]="contactForm">
            <div class="phone-block">
                <label
                    [ngClass]="{
                        'invalid-field': contactForm.controls['phone'].touched && !contactForm.controls['phone'].valid
                    }"
                >
                    <span class="label">Phone number</span>
                    <input
                        id="phone"
                        type="tel"
                        formControlName="phone"
                        required
                        [ngClass]="{
                            'warning-input':
                                contactForm.controls['phone'].touched && !contactForm.controls['phone'].valid
                        }"
                    />
                    <ng-container *ngIf="contactForm.controls['phone'].touched && !contactForm.controls['phone'].valid">
                        <span class="warning-text" *ngIf="!contactForm.controls['phone'].value"
                            >Please fill in this field to proceed</span
                        >
                        <span class="warning-text" *ngIf="contactForm.get('phone')?.hasError('pattern')"
                            >Only digits are allowed</span
                        >
                        <span class="warning-text" *ngIf="contactForm.get('phone')?.hasError('minlength')"
                            >Phone number can't include less than 10 numbers</span
                        >
                        <span class="warning-text" *ngIf="contactForm.get('phone')?.hasError('maxlength')"
                            >Phone number can't include more than 10 numbers</span
                        >
                    </ng-container>
                </label>
            </div>
            <app-picker
                [pickerData]="contactData"
                [selectedValue]="selectedContactValue"
                (optionSelected)="onContactSelected($event)"
            ></app-picker>
            <div class="terms-holder">
                <input type="checkbox" id="terms" formControlName="terms" />
                <label for="terms"
                    ><span
                        >I agree to the <a target="_blank" [href]="commonUrls.TERMS_OF_USE">Terms of Use</a><br />
                        and <a target="_blank" [href]="commonUrls.PRIVACY_POLICY">Privacy Policy.</a></span
                    ></label
                >
            </div>
            <div class="dialog-bottom">
                <button (click)="onSave()" [disabled]="contactForm.invalid" class="dialog-btn">
                    Let’s get in touch
                </button>
            </div>
        </form>
    </section>
    <div class="dialog-overlay" #overlay (click)="onClose()"></div>
</div>
