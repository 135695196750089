import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { INavigationChangeSubject } from '@common/models/profile.model';
import { NavSubject } from '@common/models/navigation.model';


@Injectable()
export class NavigationService {
    private navigationButtonSubject = new Subject<NavSubject>();
    private navigationChangeSubject = new Subject<INavigationChangeSubject>();
    private nextButtonDisabledSubject = new Subject<boolean>();
    private nextButtonActiveSubject = new Subject<boolean>();
    private nextMobileLabelSubject = new Subject<string>();
    private nextButtonLabelSubject = new Subject<string>();
    private isPrevNavigationShownSubject = new Subject<boolean>();
    private isNextNavigationShownSubject = new Subject<boolean>();
    backBrowserRoute: BehaviorSubject<string> = new BehaviorSubject<string>('');

    getNavigationButtonClicked(): Observable<NavSubject> {
        return this.navigationButtonSubject.asObservable();
    }

    setNavigationButtonClicked(value: NavSubject): void {
        this.navigationButtonSubject.next(value);
    }

    getNavigationChange(): Observable<INavigationChangeSubject> {
        return this.navigationChangeSubject.asObservable();
    }

    setNavigationChange(value: INavigationChangeSubject): void {
        this.navigationChangeSubject.next(value);
    }

    getIsNextButtonDisabled(): Observable<boolean> {
        return this.nextButtonDisabledSubject.asObservable();
    }

    setNextButtonDisabled(value: boolean): void {
        this.nextButtonDisabledSubject.next(value);
    }

    getIsNextButtonActive(): Observable<boolean> {
        return this.nextButtonActiveSubject.asObservable();
    }

    setNextButtonActive(value: boolean): void {
        this.nextButtonActiveSubject.next(value);
    }

    getNextMobileLabel(): Observable<string> {
        return this.nextMobileLabelSubject.asObservable();
    }

    setNextMobileLabel(value: string): void {
        this.nextMobileLabelSubject.next(value);
    }

    getPrevNavigationShown(): Observable<boolean> {
        return this.isPrevNavigationShownSubject.asObservable();
    }

    getNextNavigationShown(): Observable<boolean> {
        return this.isNextNavigationShownSubject.asObservable();
    }

    setPrevNavigationShown(value: boolean): void {
        this.isPrevNavigationShownSubject.next(value);
    }

    setNextNavigationShown(value: boolean): void {
        this.isNextNavigationShownSubject.next(value);
    }

    getNextButtonLabel(): Observable<string> {
        return this.nextButtonLabelSubject.asObservable();
    }

    setNextButtonLabel(value: string): void {
        this.nextButtonLabelSubject.next(value);
    }
}
