<h1>Let's confirm the details</h1>
<div class="info-block">
    <div class="data-heading">
        <div *ngIf="!isEditContactsMode" class="edit-switcher">
            <a (click)="editContactsModeOn()">Edit</a>
        </div>
        <h2>{{ firstName }} {{ lastName }}</h2>
    </div>
    <div class="data-holder">
        <ul class="data-list" *ngIf="!isEditContactsMode">
            <li>
                <img src="../../../assets/images/email-icon.svg" alt="Image of mail" />
                {{ email }}
            </li>
            <li>
                <img src="../../../assets/images/phone-black-icon.svg" alt="Image of phone" />
                {{ phone }}
            </li>
        </ul>
        <form
            *ngIf="isEditContactsMode"
            class="contact-form"
            [formGroup]="contactsForm"
            (ngSubmit)="onContactsFormSubmit()"
        >
            <div class="input-holder">
                <label
                    *ngIf="contactsForm.controls['firstName']"
                    [ngClass]="{
                        'invalid-field':
                            contactsForm.controls['firstName'].touched &&
                            contactsForm.controls['firstName'].invalid
                    }"
                >
                    <span class="label">First Name</span>
                    <input
                        type="text"
                        class="form-control st-input-field"
                        formControlName="firstName"
                        required
                        [ngClass]="{
                            'warning-input':
                                contactsForm.controls['firstName'].touched &&
                                contactsForm.controls['firstName'].invalid
                        }"
                    />
                    <ng-container
                        *ngIf="
                            contactsForm.controls['firstName'].touched &&
                            contactsForm.controls['firstName'].invalid
                        "
                    >
                        <span class="warning-text" *ngIf="!contactsForm.controls['firstName'].value"
                            >Please fill in this field to proceed</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="
                                contactsForm.get('firstName')?.hasError('pattern') ||
                                contactsForm.controls['firstName']?.hasError('maxlength')
                            "
                            >Text length should be from 1 to 35 symbols. Symbols "-", apostrophe,
                            and space are allowed</span
                        >
                    </ng-container>
                </label>
            </div>
            <div class="input-holder">
                <label
                    *ngIf="contactsForm.controls['lastName']"
                    [ngClass]="{
                        'invalid-field':
                            contactsForm.controls['lastName'].touched &&
                            contactsForm.controls['lastName'].invalid
                    }"
                >
                    <span class="label">Last name</span>
                    <input
                        type="text"
                        class="form-control st-input-field"
                        formControlName="lastName"
                        required
                        [ngClass]="{
                            'warning-input':
                                contactsForm.controls['lastName'].touched &&
                                contactsForm.controls['lastName'].invalid
                        }"
                    />
                    <ng-container
                        *ngIf="
                            contactsForm.controls['lastName'].touched &&
                            contactsForm.controls['lastName'].invalid
                        "
                    >
                        <span class="warning-text" *ngIf="!contactsForm.controls['lastName'].value"
                            >Please fill in this field to proceed</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="
                                contactsForm.get('lastName')?.hasError('pattern') ||
                                contactsForm.get('lastName')?.hasError('maxlength')
                            "
                            >Text length should be from 1 to 35 symbols. Symbols "-", apostrophe,
                            and space are allowed</span
                        >
                    </ng-container>
                </label>
            </div>
            <div class="input-holder">
                <label
                    *ngIf="contactsForm.controls['email']"
                    [ngClass]="{
                        'invalid-field':
                            contactsForm.controls['email'].touched &&
                            !contactsForm.controls['email'].valid
                    }"
                >
                    <span class="label">Email</span>
                    <input
                        type="email"
                        class="form-control st-input-field"
                        formControlName="email"
                        required
                        [ngClass]="{
                            'warning-input':
                                contactsForm.controls['email'].touched &&
                                !contactsForm.controls['email'].valid
                        }"
                    />
                    <ng-container
                        *ngIf="
                            contactsForm.controls['email'].touched &&
                            !contactsForm.controls['email'].valid
                        "
                    >
                        <span class="warning-text" *ngIf="!contactsForm.controls['email'].value"
                            >Please fill in this field to proceed</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="contactsForm.get('email')?.hasError('pattern')"
                            >Email address could contain Latin characters and special characters
                            "-", "_", ".". No spaces.</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="contactsForm.get('email')?.hasError('maxlength')"
                            >Email address should not exceed 250 characters.</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="
                                contactsForm.get('email')?.hasError('invalidDomain') &&
                                !contactsForm.get('email')?.hasError('pattern') &&
                                contactsForm.controls['email'].value
                            "
                            >Please enter a valid email address.</span
                        >
                    </ng-container>
                </label>
            </div>
            <div class="input-holder">
                <label
                    *ngIf="contactsForm.controls['phone']"
                    [ngClass]="{
                        'invalid-field':
                            contactsForm.controls['phone'].touched &&
                            !contactsForm.controls['phone'].valid
                    }"
                >
                    <span class="label">Phone number</span>
                    <input
                        type="text"
                        class="form-control st-input-field"
                        formControlName="phone"
                        required
                        [ngClass]="{
                            'warning-input':
                                contactsForm.controls['phone'].touched &&
                                !contactsForm.controls['phone'].valid
                        }"
                    />
                    <ng-container
                        *ngIf="
                            contactsForm.controls['phone'].touched &&
                            !contactsForm.controls['phone'].valid
                        "
                    >
                        <span class="warning-text" *ngIf="!contactsForm.controls['phone'].value"
                            >Please fill in this field to proceed</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="contactsForm.get('phone')?.hasError('pattern')"
                            >Only digits are allowed</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="contactsForm.get('phone')?.hasError('minlength')"
                            >Phone number can't include less than 10 numbers</span
                        >
                        <span
                            class="warning-text"
                            *ngIf="contactsForm.get('phone')?.hasError('maxlength')"
                            >Phone number can't include more than 10 numbers</span
                        >
                    </ng-container>
                </label>
            </div>
            <div class="input-holder">
                <button
                    type="button"
                    (click)="onContactsFormSubmit()"
                    [disabled]="!contactsForm.valid"
                >
                    Submit
                </button>
            </div>
        </form>
    </div>
    <ul class="data-list">
        <li *ngIf="address">
            <img src="../../../assets/images/geo-icon.svg" alt="Image of geolocation" />
            {{ address }}
        </li>
        <li *ngIf="levelsNumberText && squareFootage">
            <img src="../../../assets/images/house-icon.svg" alt="Image of house" />
            {{ levelsNumberText }}-level house, {{ squareFootage }} sq ft
        </li>
        <li>
            <img src="../../../assets/images/pets-icon.svg" alt="Image of cat" />
            <ng-container *ngIf="petsNumber"
                >I have {{ petsNumber }} pet<ng-container *ngIf="petsNumber > 1"
                    >s</ng-container
                ></ng-container
            >
            <ng-container *ngIf="!petsNumber">I don't have pets</ng-container>
        </li>
    </ul>
</div>
<div class="content-block">
    <h3>How can we contact you?</h3>
    <p>
        We'll be in touch shortly after you submit your request. If outside of business hours,
        expect follow up the next business day.
    </p>
    <app-multiple-picker
        [regularValues]="regularContactPrefValues"
        [specialValue]="specialContactPrefValue"
        [pickerData]="contactPrefData"
        [selectedValues]="contactPrefSelectedValues"
        (optionSelected)="onContactPrefSelected($event)"
    ></app-multiple-picker>
</div>
<div class="content-block">
    <h3>What days are most convenient for us to clean?</h3>
    <p>The arrival window will be agreed upon with you for each visit.</p>
    <app-multiple-picker
        class="day-picker"
        [regularValues]="regularDayValues"
        [specialValue]="specialDayValue"
        [pickerData]="daysData"
        [selectedValues]="daysSelectedValues"
        (optionSelected)="onScheduleSelected($event)"
    ></app-multiple-picker>
</div>
<div class="data-heading">
    <div class="edit-switcher"><a (click)="navigateToQuotePage()">Change quote</a></div>
    <h3 *ngIf="frequencyTitle">{{ frequencyTitle }} cleaning</h3>
</div>
<p>Here is a summary of your request</p>
<div class="info-table" *ngIf="selectedAppliancesObj?.length || selectedRoomsObj?.length">
    <table>
        <thead *ngIf="selectedRoomsObj?.length">
            <tr>
                <th>Selected Rooms</th>
                <th>Extra</th>
            </tr>
        </thead>
        <tbody *ngIf="selectedRoomsObj?.length">
            <tr *ngFor="let room of selectedRoomsObj">
                <td class="name-cell">
                    <img [src]="room.imageSrc" [alt]="'Image of ' + room.roomName" />
                    {{ room.roomName }}
                </td>
                <td class="extra-cell">
                    <ng-container *ngIf="room.isDetailed">
                        <input type="checkbox" [checked]="true" [disabled]="true" />
                        <label>Extra Sparkle</label>
                    </ng-container>
                </td>
            </tr>
        </tbody>
        <thead *ngIf="selectedAppliancesObj?.length">
            <tr>
                <th>Additional Services</th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="selectedAppliancesObj?.length">
            <tr *ngFor="let appliance of selectedAppliancesObj">
                <td class="name-cell">
                    <img [src]="appliance.imageSrc" [alt]="'Image of ' + appliance.name" />
                    {{ appliance.name }}
                </td>
                <td class="extra-cell">
                    <input type="checkbox" [checked]="true" [disabled]="true" />
                    <label>One-time</label>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="info-block price-block">
    <div class="price-holder">
        <h4>Approximate total</h4>
        <div class="price">
            ${{ firstTimePrice | number : "1.0-0" }}
            <ng-container *ngIf="!isOneTimeClean">/ ${{ recurringPrice | number : "1.0-0" }}</ng-container>
        </div>
        <span class="price-text">
            <ng-container *ngIf="!isOneTimeClean">First clean price /</ng-container>
            {{ frequencyTitle }} price
        </span>
    </div>
    <ul class="price-info">
        <li>This quote is not binding upon you or Merry Maids.</li>
        <li>
            These are the approximate charges to be assessed for the services identified in this
            quote.
        </li>
        <li>Prices exclude taxes.</li>
        <li>Your local Merry Maids location will confirm the charges.</li>
    </ul>
</div>
<div class="content-block legal-block">
    <h3>Legal Stuff</h3>
    <form [formGroup]="legalForm">
        <div class="row" *ngIf="legalForm.controls['terms']">
            <input type="checkbox" id="terms" formControlName="terms" />
            <label for="terms"
                ><span
                    >I agree to the
                    <a target="_blank" [href]="commonUrls.TERMS_OF_USE">Terms of Use</a> and
                    <a target="_blank" [href]="commonUrls.PRIVACY_POLICY">Privacy Policy.</a></span
                ></label
            >
        </div>
        <div class="row"  *ngIf="legalForm.controls['subscription']">
            <input type="checkbox" formControlName="subscription" id="subscirpion" />
            <label for="subscirpion"
                ><span
                    >By checking this box, you consent to receiving marketing emails, and texts
                    and/or calls at the number you provided including via automated technology from
                    Merry Maids, its affiliate brands, and its partners, and acknowledge that your
                    calls may be recorded. Reply “stop” or “unsubscribe” to opt out of texting. You
                    acknowledge and understand that your information is processed in accordance with
                    our <a target="_blank" [href]="commonUrls.PRIVACY_POLICY">Privacy Policy</a>.
                    Message and data rates may apply.</span
                ></label
            >
        </div>
    </form>
</div>
<div class="content-block">
    <form [formGroup]="notesForm">
        <h3>Specific needs</h3>
        <p>
            Do you have an antique table requiring special attention?<br />
            Concern about rescheduling options?<br />
            Let us know here so we can discuss when we connect!
        </p>
        <textarea
            *ngIf="notesForm.controls['notes']"
            formControlName="notes"
            type="text"
            class="notes-textarea"
            placeholder="What else is important to you?"
            [ngClass]="{ 'warning-input': notesForm.controls['notes'].invalid }"
        ></textarea>
        <ng-container *ngIf="notesForm.controls['notes']?.invalid">
            <span class="warning-text" *ngIf="notesForm.get('notes')?.hasError('maxlength')"
                >Max length should be no more than 750 characters</span
            >
            <span class="warning-text" *ngIf="notesForm.get('notes')?.hasError('pattern')"
                >Your input should not contain any prohibited symbols.</span
            >
        </ng-container>
    </form>
</div>
