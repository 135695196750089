<header>
    <ul class="address-info">
        <li *ngIf="locationName">
            <img src="assets/images/geo-green-icon.svg" width="24" height="24" alt="location"  title="Location" class="icon">
            <span *ngIf="locationName" class="location-city">{{ locationName }}</span>
        </li>
        <li *ngIf="locationPhone">
            <img src="assets/images/phone-icon.svg" width="24" height="24" alt="Phone" title="Phone" class="icon">
            <a  gaEvent="click" gaCategory="header_phone" [href]="'tel:' + locationPhone">{{ locationPhone }}</a>
        </li>
    </ul>
    <a href="https://www.merrymaids.com/" target="_blank" class="logo" gaEvent="click" gaCategory="header_logo">
        <img alt="Merry Maids" src="assets/images/logo.png" title="Merry Maids">
    </a>
</header>
