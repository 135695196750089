<div class="dialog-wrapper" *ngIf="isOpen">
    <section class="dialog">
        <button class="dialog-close" (click)="onClose()">Close</button>
        <span class="dialog-title">
            <ng-container *ngIf="!data.isDetailedMode">
                Looking for a more detailed clean of your whole home on your first clean?
            </ng-container>
            <ng-container *ngIf="data.isDetailedMode">
                Do you want to get back to 4 rooms offer?
            </ng-container>
        </span>
       <p class="dialog-text" *ngIf="!data.isDetailedMode">Select whole home Extra Sparkle below to adjust your quote.</p>
       <p class="dialog-text" *ngIf="data.isDetailedMode">The standard price includes four rooms at no additional charge.</p>
        <div class="dialog-bottom">
            <ng-container *ngIf="!data.isDetailedMode">
                <button (click)="toggleDetailedMode()" class="dialog-btn">Yes, I want whole home Extra Sparkle!</button>
                <button (click)="onClose()" class="dialog-btn action-btn">I’ll stick with Extra Sparkle in four rooms.</button>
            </ng-container>
            <ng-container *ngIf="data.isDetailedMode">
                <button (click)="toggleDetailedMode()" class="dialog-btn action-btn">I’ll stick with Extra Sparkle in four rooms.</button>
                <button (click)="onClose()" class="dialog-btn">No, I want whole home Extra Sparkle!</button>
            </ng-container>
        </div>
    </section>
    <div class="dialog-overlay" #overlay (click)="onClose()"></div>
</div>
