import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { StoreDataService } from '@common/services/store-data.service';
import { Router } from '@angular/router';
import { SpinnerService } from '@common/services/spinner.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    spinnerSubscription!: Subscription;
    isSpinnerVisible = false;
    constructor(
        private storeDataService: StoreDataService,
        private router: Router,
        public spinnerService: SpinnerService,
        private cdr: ChangeDetectorRef
    ) {
        this.storeDataService.routeToContinueBooking.subscribe((res) => {
            this.router.navigate([res], { queryParams: { continueBooking: null }, queryParamsHandling: 'merge' });
        });
    }

    ngOnInit(): void {
        this.storeDataService.setupInitialData();
        this.spinnerSubscription = this.spinnerService.getSpinnerState().subscribe((state) => {
            this.isSpinnerVisible = state;
            this.cdr.detectChanges();
        });

        console.log('app component onInit');
    }

    ngOnDestroy(): void {
        console.log('app component onDestroy');
        this.spinnerSubscription.unsubscribe();
    }
}
