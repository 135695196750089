import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavigationService } from './navigation.service';
import { ProgressBarService } from '@components/progress-bar/progress-bar.service';
import { INavigationChangeSubject } from '@common/models/profile.model';
import { ProgressBarState } from '@common/models/navigation.model';
import { BUTTON_BACK, BUTTON_NEXT } from '@constants/pages-config.constants';


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
    progressStatus!: ProgressBarState;
    navigationSubscription1!: Subscription;
    navigationSubscription2!: Subscription;
    navigationSubscription3!: Subscription;
    navigationSubscription4!: Subscription;
    navigationSubscription5!: Subscription;
    navigationSubscription6!: Subscription;
    navigationSubscription7!: Subscription;
    progressBarSubscription!: Subscription;
    isNextButtonDisabled = false;
    isNextButtonActive = false;
    nextButtonLabel = 'Next'
    nextMobileLabel = '';
    isNextButtonShown = true;
    isPrevButtonShown = true;

    constructor(
        private router: Router,
        private navigationService: NavigationService,
        private progressBarService: ProgressBarService
    ) { }

    ngOnInit(): void {
        this.progressBarSubscription = this.progressBarService.getChangedProgressBarStatus()
            .subscribe((value: ProgressBarState) => {
                this.progressStatus = value;
            });

        this.navigationSubscription1 = this.navigationService.getNavigationChange()
            .subscribe((routerLink: INavigationChangeSubject) => {
                this.changeRoute(routerLink);
            });

        this.navigationSubscription2 = this.navigationService.getIsNextButtonDisabled()
            .subscribe((value: boolean) => {
                this.isNextButtonDisabled = value;
            });

        this.navigationSubscription3 = this.navigationService.getIsNextButtonActive()
            .subscribe((value: boolean) => {
                this.isNextButtonActive = value;
            });

        this.navigationSubscription4 = this.navigationService.getNextMobileLabel()
            .subscribe((value: string) => {
                this.nextMobileLabel = value;
            });

        this.navigationSubscription5 = this.navigationService.getNextNavigationShown()
            .subscribe((value: boolean) => {
                this.isNextButtonShown = value;
            });
        this.navigationSubscription6 = this.navigationService.getPrevNavigationShown()
            .subscribe((value: boolean) => {
                this.isPrevButtonShown = value;
            });
        this.navigationSubscription7 = this.navigationService.getNextButtonLabel()
            .subscribe((value: string) => {
                this.nextButtonLabel = value;
            });
    }

    ngOnDestroy(): void {
        this.navigationSubscription1.unsubscribe();
        this.navigationSubscription2.unsubscribe();
        this.navigationSubscription3.unsubscribe();
        this.navigationSubscription4.unsubscribe();
        this.navigationSubscription5.unsubscribe();
        this.navigationSubscription6.unsubscribe();
        this.progressBarSubscription.unsubscribe();
    }

    onNextClick(): void {
        this.navigationService.setNavigationButtonClicked({
            button: BUTTON_NEXT,
            allowNavigation: false
        });
    }

    onBackClick(): void {
        this.navigationService.setNavigationButtonClicked({
            button: BUTTON_BACK,
            allowNavigation: false
        });
    }

    changeRoute(routerLink: INavigationChangeSubject): void {
        console.log('changeRoute');

        let params = {queryParamsHandling: 'merge'};
        if(routerLink.queryParams) {
            params = {
                ...params,
                ...{queryParams: routerLink.queryParams}
            }
        }
        this.router.navigate([routerLink.url], params as NavigationExtras);

    }
}
