<div class="info-container">
    <div class="info-area">
        <img
            src="../../../assets/images/not-found-img.svg"
            width="394px"
            height="275px"
            class="placeholder-image"
            alt="placeholder image"
        />
        <h1 class="title">Oops, we can’t find that page!</h1>
        <h2 *ngIf="locationPhone">Please <a [href]="'tel:' + locationPhone">call our office</a> to complete your quote.</h2>
    </div>
    <a *ngIf="initialPageUrl" [href]="initialPageUrl" class="link-back">Back to Your Clean page</a>
</div>
