import { Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-stepper-input',
    templateUrl: './stepper-input.component.html',
    styleUrls: ['./stepper-input.component.scss'],
})
export class StepperInputComponent {
    @Input() initialValue = 0;
    @Input() step = 0;
    @Input() min = 0;
    @Input() max!: number;
    @Input() gaLabel = '';
    renderedValue = '';
    @Output() valueChange = new EventEmitter<number>();
    // @Output() toggleMore = new EventEmitter();
    // @Output() toggleLess = new EventEmitter();

    value: number = this.initialValue;

    increment(): void {
        if (this.max && this.value + this.step <= this.max) {
            this.value += this.step;
            this.emitValueChange();
        }
    }

    decrement(): void {
        if (this.value - this.step >= this.min) {
            this.value -= this.step;
            this.emitValueChange();
        }
    }

    onInputChange(event: Event): void {
        const inputValue = (event.target as HTMLInputElement).valueAsNumber;
        this.value = inputValue;
    }

    onInputBlur(event: Event): void {
        const inputValue = (event.target as HTMLInputElement).valueAsNumber;
        if (inputValue < this.min) {
            this.value = this.min;
        } else if (inputValue > this.max) {
            this.value = this.max;
        } else {
            this.value = inputValue;
        }
        this.emitValueChange();
        (event.target as HTMLInputElement).value = this.value.toString();
    }

    private emitValueChange(): void {
        this.valueChange.emit(this.value);
    }

    // onClickMore(): void {
    //     if (this.max === undefined || this.value + this.step <= this.max) {
    //         this.value = this.value + this.step;
    //         this.renderedValue = this.value.toString();
    //         this.valueChange.emit(this.value);
    //         this.toggleMore.emit();
    //     }
    // }

    // onClickLess(): void {
    //     if (this.value - this.step >= this.min) {
    //         this.value = this.value - this.step;
    //         this.renderedValue = this.value.toString();
    //         this.valueChange.emit(this.value);
    //         this.toggleLess.emit();
    //     }
    // }
}
