import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-scroller-mobile',
    templateUrl: './scroller.component.html',
    styleUrls: ['./scroller.component.scss']
})
export class ScrollerComponent implements AfterViewInit, OnDestroy {
    screenHeight!: number | undefined;
    bodyElementHeight!: number | undefined;
    isScrollerButtonShown: boolean | undefined;
    timeout!: any;
    routerSubscription!: Subscription;
    isMobileScreenWidth: boolean = false;
    mobileScreenWidth: number = 768;

    constructor(private router: Router) {}

    ngAfterViewInit(): void {
        this.checkIsMobileScreen();

        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && this.isMobileScreenWidth) {
                window.scrollTo({ top: 0 });
                this.showScrollerButton();
            }
        });
    }

    showScrollerButton() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.screenHeight = window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight;
            this.bodyElementHeight = document.querySelector('body')?.clientHeight;

            if (this.bodyElementHeight && this.screenHeight) {
                this.isScrollerButtonShown = this.bodyElementHeight > this.screenHeight;
            }
    
        }, 1500);
    }

    checkIsMobileScreen() {
        this.isMobileScreenWidth = window.innerWidth <= 768;
    }

    scrollTo() {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }
}