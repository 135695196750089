import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { ProgressBarService } from '@components/progress-bar/progress-bar.service';
import { NavigationService } from '@components/navigation/navigation.service';
import { NavSubject } from '@common/models/navigation.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Event, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { BUTTON_BACK, BUTTON_NEXT, PAGE_VIEWS_MAP } from '@constants/pages-config.constants';
import { Title } from '@angular/platform-browser';
import { PAGES, PAGES_URLS } from '@constants/pages-config.constants';

@Component({
    selector: 'app-base-component',
    template: ''
})

export class BaseComponent implements OnInit, OnDestroy {
    progressBarService = inject(ProgressBarService);
    navigationService = inject(NavigationService);
    pageTitle = inject(Title)
    googleAnalyticsService = inject(GoogleAnalyticsService);
    router = inject(Router)
    progressBarStatus = {
        progress: 0,
        completedStep:0
    };
    public navigationSubscription!: Subscription;
    public navigationRoutes = {
        nextRoute: '',
        backRoute: ''
    };
    public isNextButtonDisabled = true;
    public isNextButtonActive = false;
    private subscriptions: Subscription[] = [];
    routerSub!: Subscription;
    pages = PAGES;
    pagesUrls = PAGES_URLS;
    disableNavigation = false;

    constructor () {
        this.routerSub = this.router.events
            .subscribe((event: Event) => {
                if (event instanceof NavigationEnd && event.url.includes('profileId')){
                    this.setupPageTitle(event);
                }
            });
        this.addSubscription(this.routerSub);
    }

    ngOnInit(): void {
        this.changeProgressBarStatus();
        this.navigationSubscription = this.navigationService.getNavigationButtonClicked()
            .subscribe((value: NavSubject) => {
                if(value.allowNavigation) this.onNavigationButtonClicked(value);
            });
        this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
        this.handleBrowserBackNavigation(this.disableNavigation);
        this.navigationService.setPrevNavigationShown(true);
        this.navigationService.setNextButtonLabel('Continue');
    }

    changeProgressBarStatus(): void {
        this.progressBarService.changeProgressBarStatus(this.progressBarStatus);
    }

    onNavigationButtonClicked(value: NavSubject): void {
        let routerLink: string;
        if (value.button === BUTTON_NEXT) {
            routerLink = this.navigationRoutes.nextRoute;
        } else {
            routerLink = this.navigationRoutes.backRoute;
        }
        this.navigationService.setNavigationChange({
            url: routerLink
        });
    }

    addSubscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    isNavigationButtonClicked(buttonName: string){
        return (buttonName === BUTTON_BACK || buttonName === BUTTON_NEXT)
    }

    setBrowserBackRoute(route: string){
        this.navigationService.backBrowserRoute.next(route);
    }

    handleBrowserBackNavigation(disableNavigation?: boolean) {
        history.pushState(null, '');
        const sub = fromEvent(window, 'popstate').subscribe(() => {
            history.pushState(null, '');
            if(!disableNavigation){
                setTimeout(() => {
                    this.navigationService.setNavigationChange({
                        url: this.navigationService.backBrowserRoute.value
                    });
                },0)
            }


        });
        this.subscriptions.push(sub);
    }

    setupPageTitle(event: NavigationEnd){
        // const urlSearchParams = new URLSearchParams(event?.url.split('?')[1]);
        // const profileId = urlSearchParams.get('profileId');
        const currentUrl = event.url.split('?')[0];
        const modifiedCurrentUrl = currentUrl.startsWith('/') ? currentUrl.substring(1) : currentUrl;
        const pageTitle = PAGE_VIEWS_MAP[modifiedCurrentUrl] || 'Undefined page';

        this.pageTitle.setTitle(pageTitle);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            if (!subscription.closed) subscription.unsubscribe()
        });
    }
}

