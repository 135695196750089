<ng-container *ngIf="progressStatus && isProgressBarShown">
    <div class="progress">
        <ul class="progress-list">
            <li class="step" *ngFor="let step of progressSteps; let i = index"
                [ngClass]="{
                'active': progressStatus.completedStep === i,
                'completed': progressStatus.completedStep > i}">
                <span class="step-name">{{step}}</span>
            </li>
        </ul>
    </div>
</ng-container>
