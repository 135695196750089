<div class="info-container">
    <img
        src="../../../assets/images/placeholder-img.svg"
        width="394px"
        height="300px"
        class="placeholder-image"
        alt="placeholder image"
    />
    <h1 class="title">Glad we met, you are now one of 70k+ our clients</h1>
    <h2>Thank you!</h2>
    <p class="text-info">
        Our manager has received your request and will contact you shortly. This usually takes a few minutes. After we confirm all the data, our purity fairies will immediately fly in your direction!
    </p>
    <a [href]="localSiteUrl" class="link-back">Back to main site</a>
</div>
