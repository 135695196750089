<div class="quote-container">
    <div class="heading">
        <h2>What rooms should we clean?</h2>
        <p>Please select rooms of your home to be included your cleaning quote.</p>
    </div>
    <h2>Step 1</h2>
    <div class="two-columns">
        <div class="column column-left">
            <div class="info-block">
                <h2>What is Fresh Essentials?</h2>
                <p class="info-text">
                    Our Fresh Essentials standard cleaning service offers a thorough cleaning of your rooms, including scrubbing, sanitizing, vacuuming, and dusting. While it may not be as detailed as Extra Sparkle, our professionals will ensure your spaces are fresh and clean!
                </p>
                <ul class="info-list">
                    <li *ngFor="let item of cleanTypes">
                        <span class="title"
                            ><img [src]="item.imageSrc" [alt]="'Image of ' + item.title" />{{ item.title }}</span
                        >
                        <app-expandable-list [items]="item.listEssential" [defaultVisibleCount]="2">
                        </app-expandable-list>
                    </li>
                </ul>
            </div>
        </div>
        <div class="column column-right">
            <div class="info-block">
                <div class="block-heading">
                    <h3>Select rooms</h3>
                </div>
                <ul class="rooms-list">
                    <li *ngFor="let room of allRooms">
                        <button [class.active]="room.isSelected" class="action-btn" (click)="toggleRoomSelection(room)">
                            <img
                                [src]="room.isSelected ? room.activeImageSrc : room.imageSrc"
                                [alt]="'Image of ' + room.roomName"
                            />
                            {{ room.roomName }}
                        </button>
                    </li>
                </ul>
                <div class="block-footer">
                    <button
                        [disabled]="bedroomsObj.length >= MAX_ADD_ROOMS_NUMBER"
                        (click)="addRoom('bedroom')"
                        class="action-btn"
                    >
                        <img src="../../../assets/images/plus-icon.svg" alt="plus icon" />
                        Add bedroom
                    </button>
                    <button
                        [disabled]="fullBathroomsObj.length >= MAX_ADD_ROOMS_NUMBER"
                        (click)="addRoom('bathroom')"
                        class="action-btn"
                    >
                        <img src="../../../assets/images/plus-icon.svg" alt="plus icon" />
                        Add full bath
                    </button>
                    <button
                        [disabled]="halfBathroomsObj.length >= MAX_ADD_ROOMS_NUMBER"
                        (click)="addRoom('half bath')"
                        class="action-btn"
                    >
                        <img src="../../../assets/images/plus-icon.svg" alt="plus icon" />
                        Add half bath
                    </button>
                </div>
            </div>
        </div>
    </div>
    <h2>Step 2</h2>
    <div class="two-columns">
        <div class="column column-left">
            <div class="info-block sparkle-block">
                <h2>What is Extra Sparkle</h2>
                <p class="info-text">
                    Extra Sparkle, our most detailed cleaning service, is included in the price of your first clean, for four rooms, at no additional cost! Future cleans include Fresh Essentials for all rooms and Extra Sparkle for two rooms.
                </p>
                <ul class="info-list">
                    <li *ngFor="let item of cleanTypes">
                        <span class="title"
                            ><img [src]="item.imageSrc" [alt]="'Image of ' + item.title" />{{ item.title }}</span
                        >
                        <app-expandable-list [items]="item.listSparkle" [defaultVisibleCount]="2">
                        </app-expandable-list>
                    </li>
                </ul>
                <button [class.active]="isDetailedMode" class="action-btn sparkle-btn" (click)="openDetailedDialog()">
                    <img
                        [src]="
                            isDetailedMode
                                ? '../../../assets/images/sparkle-small-icon.svg'
                                : '../../../assets/images/sparkle-icon.svg'
                        "
                        alt="image of sparkles"
                    />
                    <span>Add Extra Sparkle <br /><span class="add-text">to whole home</span></span>
                </button>
            </div>
        </div>
        <div class="column column-right">
            <div class="info-block" *ngIf="isAnyRoomSelected">
                <div class="block-heading">
                    <h3>Add Extra Sparkle</h3>
                    <span class="add-info" *ngIf="!isDetailedMode"
                        >{{ detailedRoomsCounter }}/{{ MAX_DETAILED_ROOMS }} selected</span
                    >
                </div>
                <ul class="rooms-list">
                    <ng-container *ngFor="let room of allRooms">
                        <li *ngIf="room.isSelected">
                            <button
                                [disabled]="
                                    (!room.isDetailed && detailedRoomsCounter >= MAX_DETAILED_ROOMS) || !room.isSelected
                                "
                                [class.active]="room.isDetailed"
                                class="action-btn"
                                (click)="isDetailedMode ? openDetailedDialog() : toggleRoomDetailed(room)"
                            >
                                <img
                                    [src]="room.isDetailed ? room.activeImageSrc : room.imageSrc"
                                    [alt]="'Image of ' + room.roomName"
                                />
                                {{ room.roomName }}
                                <img
                                    class="sparkle-icon"
                                    [src]="
                                        room.isDetailed
                                            ? '../../../assets/images/sparkle-small-icon.svg'
                                            : '../../../assets/images/sparkle-icon.svg'
                                    "
                                    alt="image of sparkles"
                                />
                            </button>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
    <div class="heading">
        <h2>Additional services</h2>
        <p>
            Please note these are completed and charged only on your first clean.<br />Should you want these later, just
            let us know!
        </p>
        <div class="info-table">
            <table>
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                id="allAppliancesCheckbox"
                                [checked]="isAllAppliancesSelected()"
                                (change)="toggleAllAppliancesSelection($event)"
                            />
                            <label for="allAppliancesCheckbox">Service</label>
                        </th>
                        <th width="276px">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [class.selected]="appliance.isSelected" *ngFor="let appliance of appliancesObj">
                        <td>
                            <input
                                type="checkbox"
                                (change)="toggleApplianceSelection(appliance, $event)"
                                [checked]="appliance.isSelected"
                                [id]="appliance.type"
                            />
                            <label [for]="appliance.type"
                                ><img [src]="appliance.imageSrc" [alt]="'Image of ' + appliance.name" />{{
                                    appliance.name
                                }}</label
                            >
                        </td>
                        <td>
                            <button
                                [class.active]="appliance.isSelected"
                                (click)="toggleApplianceSelection(appliance)"
                                class="action-btn cell-btn"
                            >
                                <span *ngIf="appliance.isSelected" class="check-icon"></span>
                                One-time price - ${{ appliance.price | number : "1.0-0" }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="page-block">
        <div class="heading">
            <h2>How often would you like us to clean?</h2>
        </div>
        <app-picker
            [pickerData]="frequencyData"
            [selectedValue]="selectedFrequencyValue"
            (optionSelected)="onFrequencySelected($event)"
        ></app-picker>
    </div>
    <div *ngIf="selectedFrequencyValue === cleaningFrequency.ONE_TIME" class="page-block">
        <div class="heading">
            <h2>What occasion we are cleaning for?</h2>
            <p>Every clean is unique and knowing your situation helps us to prepare!</p>
        </div>
        <app-picker
            [pickerData]="occasionData"
            [selectedValue]="selectedOccasionValue"
            (optionSelected)="onOccasionSelected($event)"
        ></app-picker>
    </div>
    <div class="price-block">
        <div class="price-area">
            <ng-container *ngIf="!isOneTimeClean">
                <div class="price-container">
                    <div class="price-holder">
                        <p class="price" [class.active]="isAnyRoomSelected">
                            $<span class="plug-text">{{
                                isAnyRoomSelected ? (firstTimePrice | number : "1.0-0") : "TBC"
                            }}</span>
                        </p>
                        <p class="add-info">First clean price</p>
                    </div>
                    <div class="price-holder">
                        <p class="price" [class.active]="isAnyRoomSelected">
                            $<span class="plug-text">{{
                                isAnyRoomSelected ? (recurringPrice | number : "1.0-0") : "TBC"
                            }}</span>
                        </p>
                        <p class="add-info">Recurring clean price</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isOneTimeClean">
                <div class="price-holder">
                    <p class="price" [class.active]="isAnyRoomSelected">
                        $<span class="plug-text">{{
                            isAnyRoomSelected ? (firstTimePrice | number : "1.0-0") : "TBC"
                        }}</span>
                    </p>
                    <p class="add-info">One-time clean price</p>
                </div>
            </ng-container>
        </div>
        <div class="text-area">
            <h2>Have a different budget in mind?</h2>
            <p class="add-text">
                If your budget doesn’t match this quote, we are happy to create a personalized cleaning plan that works for you.
            </p>
            <div class="btn-holder">
                <button (click)="openContactDialog()" class="action-btn">
                    <img src="../../../assets/images/call-in-icon.svg" alt="Image of phone" />
                    <span>Contact me!</span>
                </button>
                <a [href]="'tel:' + locationPhone" *ngIf="locationPhone" class="link-btn" [attr.aria-label]="'Call us at ' + locationPhone">
                    <img src="../../../assets/images/call-out-icon.svg" alt="Image of phone" />
                    <span>Call us now<br>{{ locationPhone }}</span>
                </a>
            </div>
        </div>
    </div>
</div>
<app-detailed-dialog
    [isOpen]="isDetailedDialogOpen"
    [data]="detailedDialogData"
    (closeDialog)="onDetailedDialogClosed($event)"
>
    ></app-detailed-dialog
>
<app-contact-dialog
    [isOpen]="isContactDialogOpen"
    [data]="contactDialogData"
    (closeDialog)="onContactDialogClosed($event)"
></app-contact-dialog>
