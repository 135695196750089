import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProgressBarState } from '@common/models/navigation.model';

@Injectable()
export class ProgressBarService {
    private subject = new Subject<ProgressBarState>();

    changeProgressBarStatus(value: ProgressBarState) {
        this.subject.next(value);
    }

    getChangedProgressBarStatus(): Observable<ProgressBarState> {
        return this.subject.asObservable();
    }
}
