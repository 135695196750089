import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout, tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  private readonly TIMEOUT_DURATION = 90000; // 90 seconds in milliseconds
  private ongoingRequests = 0;

  constructor(private router: Router, private spinnerService: SpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    this.ongoingRequests++;

    let customHeaders = request.headers;
    customHeaders = customHeaders.set('Content-Type', 'application/json')
    customHeaders = customHeaders.set('property', 'value')

    const clonedRequest = request.clone({
      headers: customHeaders
    });
    this.spinnerService.show();
    return next.handle(clonedRequest).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const endTime = Date.now();
          const duration = endTime - startTime;
        //   console.log('');
        //   console.log(`URL: ${request.url}`);
        //   console.log(`Status: ${event.status}, Response Time: ${duration}ms`);
        //   console.log('');
        }
      }),
      timeout(this.TIMEOUT_DURATION),
      catchError((error: any) => {
        if(error.name === "TimeoutError"){
          console.log('timeout error', error.message)
        } else if (error instanceof HttpErrorResponse){
          switch (error.status) {
            case 401: console.log('401')
            break;
            case 404: console.log('404')
            break;
            case 403: console.log('403')
            break;
            case 500: console.log('500')
          }
          alert(error.error)
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ongoingRequests--;
        if (this.ongoingRequests === 0) {
          this.spinnerService.hide();
        }
      })
    );
  }
}
