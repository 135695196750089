import { CleanType } from '@common/models/clean-type.model';

export const CLEANING_FREQUENCY = {
  ONE_TIME: 'OneTime',
  WEEKLY: 'weekly',
  BI_WEEKLY: 'x2week',
  MONTHLY: 'monthly',
}

export const OCCASIONS = {
  MOVING_IN: 'moving-in',
  MOVING_OUT: 'moving-out',
  SPECIAL: 'special-occasion',
  OTHER: 'something-else'
}

export const CLEAN_TYPES: Array<CleanType> = [
    {
        imageSrc: '../../../assets/images/kitchen-icon.svg',
        title: 'Kitchen',
        listEssential: ['Clean counters, cabinet exteriors, table and chairs', 'Clean, scrub and sanitize sinks countertops and backsplashes', 'Clean exterior of all appliances', 'Clean inside and outside of microwave', 'Vacuum and wash floor'],
        listSparkle: ['Wet wipe all cabinet fronts, table and chairs', 'Wet wipe all basboards'],
    },
    {
        imageSrc: '../../../assets/images/halfbath-icon.svg',
        title: 'Bathroom',
        listEssential: ['Clean, sanitize and deodorize', 'Clean, scrub and sanitize showers and bathtubs', 'Vanities, sinks and backsplashes cleaned and sanitized', 'Clean mirrors', 'Clean and sanitize toilets', 'Vacuum small rugs and floor, wash floor'],
        listSparkle: ['Wet wipe cabinet fronts and tile walls', 'Wet wipe all baseboards and blinds'],
    },
    {
        imageSrc: '../../../assets/images/living-room-icon.svg',
        title: 'All Rooms',
        listEssential: ['Clean, dust and vacuum', 'Dusting window sills, ledges, doors, blinds, picture frames, knickknacks, ceiling fans, lamps, funiture, woodwork, shelves and baseboards', 'Remove cobwebs', 'Vacuum and wash all hardsurface flooring', 'Vacuum carpets', 'Vacuum furniture including under seat cushions', 'Empty wastebaskets', 'Dust lightswitch plates and vents'],
        listSparkle: ['Wet wipe all blinds, window sills, ledges, doors, pictures, knickknacks, ceiling fans, lamps, woodwork, shelves, and baseboards', 'Vacuum back and sides of furniture', 'Edge carpet', 'Wet wipe lightswitch plates and vents'],
    }
];
