export const COMMON_URLS = {
    PRIVACY_POLICY: 'https://www.servicemaster.com/privacy-policy/',
    TERMS_OF_USE: 'https://www.merrymaids.com/terms-of-use/',
    PAYMENT_AND_CANCELLATION: 'https://www.merrymaids.com/terms-of-use/#Payment',
    DO_NOT_SHARE:'https://www.servicemaster.com/do-not-sell-share-my-info/',
    ACCESSIBILITY: 'https://www.servicemaster.com/accessibility-statement/',
    FACEBOOK: 'https://www.facebook.com/MerryMaids',
    INSTAGRAM: 'https://www.instagram.com/merrymaidsofficial/',
    YOUTUBE: 'https://www.youtube.com/user/merrymaids',
    LOCAL_SITE: 'https://www.merrymaids.com/location-lookup/',
  }
