import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

import { BaseComponent } from '@components/base-component';
import { StoreDataService } from '@common/services/store-data.service';
import { Profile } from '@common/models/profile.model';
import { phoneLengthValidator, domainValidator } from '@common/validators/formValidators';
import { COMMON_URLS } from '@constants/common-urls.constants';
import { PickerData } from '@common/models/picker-option.model';
import { BUTTON_NEXT } from '@constants/pages-config.constants';

@Component({
    selector: 'app-your-clean',
    templateUrl: './your-clean.component.html',
    styleUrls: ['./your-clean.component.scss'],
})
export class YourCleanComponent extends BaseComponent implements OnInit {
    commonUrls = COMMON_URLS;
    override progressBarStatus = {
        progress: 0,
        completedStep: 0,
    };
    override navigationSubscription!: Subscription;
    override navigationRoutes = {
        nextRoute: this.pagesUrls[this.pages.QUOTE],
        backRoute: '',
    };
    override isNextButtonDisabled = true;
    override isNextButtonActive = false;
    isWelcomeTooltipShown = false;
    openPageTime: Date | null = null;
    contactsForm: FormGroup = new FormGroup({});
    address!: string;
    city!: string;
    localSiteUrl = this.commonUrls.LOCAL_SITE;
    addressLoaded = false;
    nextButtonText = 'Continue';

    squareFootage!: number;
    footageDefaultValue = 1000;
    footageStringValue = '1000';
    footageOptions: Options = {
        floor: 500,
        ceil: 4000,
        step: 500,
        translate: (value: number): string => {
            return this.formatValue(value);
        },
        showTicks: true,
    };
    formatValue(value: number): string {
        return value.toLocaleString('en-US') + ` ft²`;
    }
    levelsData: Array<PickerData> = [
        { label: 'One level', value: 1 },
        { label: 'Two levels', value: 2 },
        { label: 'Three levels', value: 3 },
        { label: 'Four levels', value: 4 },
    ];
    levelsNumber!: number;

    petsNumber!: number;
    petsInitialValue = 0;
    petsMinValue = 0;
    petsMaxValue = 10;
    petsStep = 1;

    constructor(
        private formBuilder: FormBuilder,
        private storeDataService: StoreDataService,
    ) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.openPageTime = new Date();
        super.setBrowserBackRoute(this.navigationRoutes.backRoute);
        this.navigationService.setNextButtonLabel(this.nextButtonText);
        this.buildContactsForm();
        this.handleContactForm();
        const nextButtonSubscription = this.navigationService
            .getNavigationButtonClicked()
            .subscribe((res) => {
                if (this.isNavigationButtonClicked(res.button) && !res.allowNavigation) {
                    this.storeCurrentData(res.button);
                    if (this.openPageTime) {
                        const closePageTime = new Date();
                        const timeDifference = (closePageTime.getTime() - this.openPageTime.getTime()) / 1000;
                        this.googleAnalyticsService.event('leave_page', 'navigation', this.pagesUrls[this.pages.YOUR_CLEAN], timeDifference);
                    }
                }
            });
        const profileSubscription = this.storeDataService
            .profileObserver()
            .subscribe((profile: Profile) => {
                if (profile.profileId) this.setupDefaultOptions(profile);
            });
        this.addSubscription(nextButtonSubscription);
        this.addSubscription(profileSubscription);
        this.navigationService.setPrevNavigationShown(false);
    }

    setupDefaultOptions(profile: Profile) {
        const { address, postalCode, city, state, localSiteUrl } =
            this.storeDataService.profileData;
        const suite =
            this.storeDataService.profileData.suite &&
            this.storeDataService.profileData.suite !== 'null'
                ? ', ' + this.storeDataService.profileData.suite
                : '';
        this.addressLoaded = !!address;
        this.address = `${address}${suite}, ${city}, ${state} ${postalCode}`;
        this.city = city ? city : '';
        if (localSiteUrl) {
            this.localSiteUrl = localSiteUrl;
        }
        if (profile.firstName) {
            this.contactsForm.get('firstName')?.setValue(profile.firstName);
            this.contactsForm.controls['firstName'].markAsTouched();
        }
        if (profile.lastName) {
            this.contactsForm.get('lastName')?.setValue(profile.lastName);
            this.contactsForm.controls['lastName'].markAsTouched();
        }
        if (profile.email) {
            this.contactsForm.get('email')?.setValue(profile.email);
            this.contactsForm.controls['email'].markAsTouched();
        }
        if (profile.phone) {
            this.contactsForm.get('phone')?.setValue(profile.phone);
            this.contactsForm.controls['phone'].markAsTouched();
        }
        if (profile.petsNumber) {
            this.petsInitialValue = profile.petsNumber;
        }
        if (profile.levels) {
            this.levelsNumber = profile.levels;
            this.handleNavigation();
        }
        if (profile.square) {
            this.squareFootage = profile.square;
            this.footageDefaultValue = profile.square;
            this.footageStringValue = profile.square.toString();
        }
    }

    storeCurrentData(button: string) {
        if (this.contactsForm.valid && this.levelsNumber) {
            const contactFormValues = { ...this.contactsForm.value };

            this.storeDataService
                .storeCurrentData({
                    ...contactFormValues,
                    levels: this.levelsNumber,
                    square: this.squareFootage || this.footageDefaultValue,
                    petsNumber: this.petsNumber || 0,
                })
                .subscribe(() => {
                    this.navigationService.setNavigationButtonClicked({
                        button: button,
                        allowNavigation: true,
                    });
                });
        } else {
            this.navigationService.setNavigationButtonClicked({
                button: button,
                allowNavigation: true,
            });
        }
    }

    buildContactsForm(): void {
        this.contactsForm = this.formBuilder.group({
            firstName: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(35),
                Validators.pattern("^(?!\\s*$)[a-zA-Z-'`‘’()\u2018\u2019 ]*$"),
            ]),
            lastName: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(35),
                Validators.pattern("^(?!\\s*$)[a-zA-Z-'`‘’()\u2018\u2019 ]*$"),
            ]),
            email: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(250),
                domainValidator,
                Validators.pattern(
                    /^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/
                ),
            ]),
            phone: new FormControl(null, [
                Validators.required,
                phoneLengthValidator(10, 10),
                Validators.pattern('^[0-9 ]*$'),
            ]),
        });
    }

    firstNameOnBlur() {
        this.isWelcomeTooltipShown = false;
        if (
            this.contactsForm.controls['firstName'].touched &&
            this.contactsForm.controls['firstName'].invalid
        ) {
            this.googleAnalyticsService.event('error_shown', 'input_error', 'First Name Error');
        }
    }

    lastNameOnBlur() {
        if (
            this.contactsForm.controls['lastName'].touched &&
            this.contactsForm.controls['lastName'].invalid
        ) {
            this.googleAnalyticsService.event('error_shown', 'input_error', 'Last Name Error');
        }
    }
    emailOnBlur() {
        if (
            this.contactsForm.controls['email'].touched &&
            this.contactsForm.controls['email'].invalid
        ) {
            this.googleAnalyticsService.event('error_shown', 'input_error', 'Email Error');
        }
    }
    phoneOnBlur() {
        if (
            this.contactsForm.controls['phone'].touched &&
            this.contactsForm.controls['phone'].invalid
        ) {
            this.googleAnalyticsService.event('error_shown', 'input_error', 'Phone Error');
        }
    }

    handleContactForm(): void {
        const contactsFormSubscription = this.contactsForm.valueChanges.subscribe(() => {
            this.handleNavigation();
        });
        this.addSubscription(contactsFormSubscription);
    }

    onContactsFormFocusOut() {
        Object.keys(this.contactsForm.controls).forEach((key) => {
            const control = this.contactsForm.get(key);

            if (control) {
                const value = control.value;

                if (value !== null && value !== undefined) {
                    let trimmedValue = value.trim();
                    if (key === 'phone') {
                        trimmedValue = value.trim().replace(/\s/g, '');
                    }
                    if (trimmedValue.length > 0) {
                        control.setValue(trimmedValue);
                    } else {
                        control.setValue(null);
                    }
                }
            }
        });
        if (this.contactsForm.valid) {
            const contactFormValues = { ...this.contactsForm.value };
            this.storeDataService
                .storeCurrentData({
                    ...contactFormValues,
                })
                .subscribe();
        }
    }

    onLevelsSelected(selectedTab: number | string): void {
        if (selectedTab) {
            this.levelsNumber = selectedTab as number;
            this.handleNavigation();
        }
    }

    onFootageValueChanged(value: number): void {
        this.squareFootage = value;
    }

    onPetsValueChange(value: number): void {
        this.petsNumber = value;
    }

    handleNavigation() {
        if (this.contactsForm.valid && this.levelsNumber) {
            this.isNextButtonDisabled = false;
            this.isNextButtonActive = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
            this.navigationService.setNextButtonActive(this.isNextButtonActive);
        } else {
            this.isNextButtonDisabled = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
        }
    }

    sendManagerRequest() {
        this.navigationRoutes.nextRoute = this.pagesUrls[this.pages.SPECIAL_ATTENTION];
        this.storeDataService.sendOrder('4000').subscribe(() => {
            if (this.openPageTime) {
                const closePageTime = new Date();
                const timeDifference = (closePageTime.getTime() - this.openPageTime.getTime()) / 1000;
                this.googleAnalyticsService.event('leave_page', 'navigation', this.pagesUrls[this.pages.YOUR_CLEAN], timeDifference);
            }
            this.navigationService.setNavigationButtonClicked({
                button: BUTTON_NEXT,
                allowNavigation: true,
            });
        });
    }
}
