import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Profile } from '@common/models/profile.model';
import { StoreDataService } from '@common/services/store-data.service';
import { BaseComponent } from '@components/base-component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent extends BaseComponent implements OnInit, OnDestroy {
    override progressBarStatus = {
        progress: 0,
        completedStep: 0,
        isProgressBarShown: false,
    };
    override disableNavigation = true;
    locationPhone!: string;
    initialPageUrl!: string;
    profileSubscription!: Subscription;

    constructor(private storeDataService: StoreDataService, private renderer: Renderer2) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.renderer.addClass(document.body, 'special-page');
        this.profileSubscription = this.storeDataService
            .profileObserver()
            .subscribe((profile: Profile) => {
                if (profile.profileId) {
                    if (profile.locationPhone) {
                        this.locationPhone = this.phoneFormatter(profile.locationPhone);
                    }
                    let locationCode = profile.locationCode;
                    let serviceTerritoryID = profile.serviceTerritoryID;
                    let profileId = profile.profileId;
                    if (locationCode && serviceTerritoryID && profileId) {
                        this.initialPageUrl = `/your-clean?locationCode=${locationCode}&serviceTerritoryID=${serviceTerritoryID}&profileId=${profileId}`;
                        // this.router.navigateByUrl(targetUrl);
                    }
                }
            });
        this.addSubscription(this.profileSubscription);
        this.navigationService.setNextNavigationShown(false);
        this.navigationService.setPrevNavigationShown(false);
        this.progressBarService.changeProgressBarStatus(this.progressBarStatus);
    }

    phoneFormatter(phone: string): string {
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.renderer.removeClass(document.body, 'your-special-class');
    }
}
