export const PAGES = {
    YOUR_CLEAN: 'YOUR_CLEAN',
    QUOTE: 'QUOTE',
    SUMMARY: 'SUMMARY',
    BOOKING_COMPLETED: 'BOOKING_COMPLETED',
    SPECIAL_ATTENTION: 'SPECIAL_ATTENTION',
    MANAGER_REQUEST: 'MANAGER_REQUEST',
    NOT_FOUND: 'NOT_FOUND',
};
export const PAGE_TITLES = {
    [PAGES.YOUR_CLEAN]: 'Your Clean',
    [PAGES.QUOTE]: 'Quote',
    [PAGES.SUMMARY]: 'Summary',
    [PAGES.BOOKING_COMPLETED]: 'Booking completed',
    [PAGES.SPECIAL_ATTENTION]: 'Special attention',
    [PAGES.MANAGER_REQUEST]: 'Manager request',
    [PAGES.NOT_FOUND]: 'Page not found',
};

export const PAGES_URLS = {
    [PAGES.YOUR_CLEAN]: 'your-clean',
    [PAGES.QUOTE]: 'quote',
    [PAGES.SUMMARY]: 'summary',
    [PAGES.BOOKING_COMPLETED]: 'booking-completed',
    [PAGES.SPECIAL_ATTENTION]: 'special-attention',
    [PAGES.MANAGER_REQUEST]: 'manager-request',
    [PAGES.NOT_FOUND]: 'not-found',
};
export const PAGE_VIEWS_MAP: { [key: string]: string } = {
    [PAGES_URLS[PAGES.YOUR_CLEAN]]: PAGE_TITLES[PAGES.YOUR_CLEAN],
    [PAGES_URLS[PAGES.QUOTE]]: PAGE_TITLES[PAGES.QUOTE],
    [PAGES_URLS[PAGES.SUMMARY]]: PAGE_TITLES[PAGES.SUMMARY],
    [PAGES_URLS[PAGES.BOOKING_COMPLETED]]: PAGE_TITLES[PAGES.BOOKING_COMPLETED],
    [PAGES_URLS[PAGES.SPECIAL_ATTENTION]]: PAGE_TITLES[PAGES.SPECIAL_ATTENTION],
    [PAGES_URLS[PAGES.MANAGER_REQUEST]]: PAGE_TITLES[PAGES.MANAGER_REQUEST],
    [PAGES_URLS[PAGES.NOT_FOUND]]: PAGE_TITLES[PAGES.NOT_FOUND],
};

export const BUTTON_BACK = 'button-back';
export const BUTTON_NEXT = 'button-next';
