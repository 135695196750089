import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@components/base-component';
import { StoreDataService } from '@common/services/store-data.service';
import { PricingQuoteService } from '@common/services/pricing-quote.service';
import { DialogService } from '@common/services/dialog.service';
import { Profile } from '@common/models/profile.model';
import { FrequencyType } from '@common/models/frequency-type.model';
import { BUTTON_BACK, BUTTON_NEXT } from '@constants/pages-config.constants';
import {
    Room,
    RoomObj,
    Appliance,
    ApplianceObj,
    SelectedAppliance,
} from '@common/models/rooms.model';
import { Price } from '@common/models/price.model';
import { PickerData } from '@common/models/picker-option.model';
import { CLEANING_FREQUENCY, OCCASIONS, CLEAN_TYPES } from '@constants/quote-properties.constants';
import { ALL_ROOMS_OBJ, APPLIANCES_OBJ, RoomName, ROOM_NAMES } from '@constants/room-data.constants';

@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent extends BaseComponent implements OnInit, OnDestroy {
    override progressBarStatus = {
        progress: 2,
        completedStep: 1,
    };
    override navigationSubscription!: Subscription;
    override navigationRoutes = {
        nextRoute: this.pagesUrls[this.pages.SUMMARY],
        backRoute: this.pagesUrls[this.pages.YOUR_CLEAN],
    };
    override isNextButtonDisabled = true;
    override isNextButtonActive = false;
    nextButtonText = 'Continue';

    subscriptionType: string | null = null;
    profileSubscription!: Subscription;

    cleanTypes = CLEAN_TYPES;
    roomNames = ROOM_NAMES;

    frequencyTypesMap: Array<FrequencyType> = [];
    selectedFrequency: FrequencyType | null = null;

    cleaningFrequency = CLEANING_FREQUENCY;
    selectedFrequencyValue: string = this.cleaningFrequency.WEEKLY;
    frequencyData: Array<PickerData> = [
        { label: 'Weekly', value: this.cleaningFrequency.WEEKLY },
        { label: 'Every other week', value: this.cleaningFrequency.BI_WEEKLY },
        { label: 'Every four weeks', value: this.cleaningFrequency.MONTHLY },
        { label: 'One-time', value: this.cleaningFrequency.ONE_TIME },
    ];

    occasions = OCCASIONS;
    occasionData: Array<PickerData> = [
        { label: 'Moving out', value: this.occasions.MOVING_OUT },
        { label: 'Moving in', value: this.occasions.MOVING_IN },
        { label: 'Special occasion', value: this.occasions.SPECIAL },
        { label: 'Other', value: this.occasions.OTHER },
    ];
    selectedOccasionValue!: string;

    isOneTimeClean = false;
    oneTimeStandardClean: number | null = null;

    otherRooms: Room[] = [];
    otherRoomsObj: RoomObj[] = [];
    rooms: Room[] = [];
    bedroomsObj: RoomObj[] = [];
    fullBathroomsObj: RoomObj[] = [];
    halfBathroomsObj: RoomObj[] = [];

    locationPhone: string | null = null;

    get allRooms() {
        return [
            ...this.bedroomsObj,
            ...this.fullBathroomsObj,
            ...this.halfBathroomsObj,
            ...this.otherRoomsObj,
        ];
    }

    isAnyRoomSelected = false;

    isDetailedMode = false;

    appliancesObj!: ApplianceObj[];
    selectedAppliances!: SelectedAppliance[];

    BEDROOMS_INITIAL_NUMBER = 1;
    BATHROOMS_INITIAL_NUMBER = 1;
    HALF_BATHROOMS_INITIAL_NUMBER = 1;
    MAX_ADD_ROOMS_NUMBER = 10;

    MAX_DETAILED_ROOMS = 4;
    detailedRoomsCounter: number = 0;

    price!: Price;
    firstTimePrice!: number | null;
    recurringPrice!: number | null;

    isContactDialogOpen = false;
    contactDialogData!: {
        phoneNumber: string;
    };
    isDetailedDialogOpen = false;
    detailedDialogData!: {
        isDetailedMode: boolean;
    };

    dialogSubscription!: Subscription;
    detailedDialogSubscription!: Subscription;
    contactDialogSubscription!: Subscription;
    dialogDetailedService!: DialogService;
    dialogContactService!: DialogService;
    constructor(
        private storeDataService: StoreDataService,
        private pricingQuoteService: PricingQuoteService,
        private cd: ChangeDetectorRef
    ) {
        super();
        this.dialogDetailedService = new DialogService();
        this.dialogContactService = new DialogService();
        this.dialogDetailedService.dialogState$.subscribe((state) => {
            this.isDetailedDialogOpen = state.isOpen;
            this.detailedDialogData = state.data;
        });
        this.dialogContactService.dialogState$.subscribe((state) => {
            this.isContactDialogOpen = state.isOpen;
            this.contactDialogData = state.data;
        });
    }

    override ngOnInit(): void {
        super.ngOnInit();
        super.setBrowserBackRoute(this.navigationRoutes.backRoute);
        this.navigationService.setNextButtonActive(this.isNextButtonActive);
        const nextButtonSubscription = this.navigationService
            .getNavigationButtonClicked()
            .subscribe((res) => {
                if (this.isNavigationButtonClicked(res.button) && !res.allowNavigation) {
                    this.storeCurrentData(res.button);
                }
            });

        this.profileSubscription = this.storeDataService
            .profileObserver()
            .subscribe((profile: Profile) => {
                if (profile.profileId) this.setupDefaultOptions(profile);
            });
        this.addSubscription(this.profileSubscription);
        this.addSubscription(nextButtonSubscription);
    }

    setupDefaultOptions(profile: Profile) {
        if (profile?.locationCode) {
            this.getSubscriptionType(profile.locationCode);
        }

        if (profile.otherRooms?.length) {
            this.getOtherRooms(profile);
        }

        if (profile.rooms?.length) {
            this.getRooms(profile);
            this.getAppliances(profile);
            this.checkIsAnyRoomSelected();
            this.checkIsDetailedMode();
        } else {
            this.getRoomTypes();
            this.getAppliances(profile);
        }

        if (profile.locationPhone) {
            this.locationPhone = this.phoneFormatter(profile.locationPhone);
        }

        if (profile.cleaningFrequency) {
            this.selectedFrequencyValue = profile.cleaningFrequency;
        }
        if (profile.cleaningOccasion) {
            this.selectedOccasionValue = profile.cleaningOccasion as string;
        }

        this.countDetailedRooms();
        this.handleNavigation();
        this.checkIsDetailedMode();
        this.navigationService.setNextButtonLabel(this.nextButtonText);
    }

    phoneFormatter(phone: string): string {
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    }

    getSubscriptionType(locationCode: number) {
        this.pricingQuoteService.getQuoteRate(locationCode).subscribe((res) => {
            this.subscriptionType = res.subscriptionType;
        });
    }

    getAppliances(profile: Profile) {
        this.pricingQuoteService
            .getAppliances(profile.locationCode)
            .subscribe((appliances: Appliance[]) => {
                this.mapAppliances(appliances);
                this.getPricingQuote();
            });
    }

    getOtherRooms(profile: Profile) {
        this.otherRooms = profile.otherRooms?.map((room) => ({ ...room })) as Room[];
        this.otherRoomsObj = this.mapOtherRooms();
    }

    getRooms(profile: Profile) {
        this.rooms = profile.rooms?.map((room) => ({ ...room })) as Room[];
        this.bedroomsObj = this.mapRooms(this.roomNames.BEDROOM);
        this.fullBathroomsObj = this.mapRooms(this.roomNames.BATHROOM);
        this.halfBathroomsObj = this.mapRooms(this.roomNames.HALF_BATH);
    }

    openDetailedDialog(): void {
        const data = {
            isDetailedMode: this.isDetailedMode,
        };
        this.dialogDetailedService.openDialog(data).subscribe((result) => {
            this.cd.detectChanges();
            if (result.result !== null) {
                this.toggleDetailedMode(result.result);
            }
        });
    }

    onDetailedDialogClosed(event: Event): void {
        this.dialogDetailedService.closeDialog(event);
        if (this.detailedDialogSubscription) {
            this.detailedDialogSubscription.unsubscribe();
        }
    }

    openContactDialog(): void {
        const data = {
            phoneNumber: this.storeDataService.profileData.phone,
        };
        this.dialogContactService.openDialog(data).subscribe((result) => {
            if (result.result?.contactPreference && result.result?.phone) {
                const contactPreference: string[] = [];
                contactPreference.push(result.result.contactPreference);
                this.storeDataService
                    .storeCurrentData({
                        contactPreference: contactPreference,
                        phone: result.result.phone,
                    })
                    .subscribe(() => {
                        this.storeDataService.sendOrder('CustomizePrice').subscribe(() => {
                            this.navigationRoutes.nextRoute = this.pagesUrls[this.pages.MANAGER_REQUEST];
                            this.navigationService.setNavigationButtonClicked({
                                button: BUTTON_NEXT,
                                allowNavigation: true,
                            });
                        });
                    });
            }
        });
    }

    onContactDialogClosed(event: Event): void {
        this.dialogContactService.closeDialog(event);
        if (this.contactDialogSubscription) {
            this.contactDialogSubscription.unsubscribe();
        }
    }

    getRoomTypes() {
        this.pricingQuoteService
            .getRoomTypes(this.storeDataService.locationCode, {
                levels: this.storeDataService.profileData.levels,
                numberOfBedrooms: this.BEDROOMS_INITIAL_NUMBER,
                numberOfBathrooms: this.BATHROOMS_INITIAL_NUMBER,
                numberOfHalfbathrooms: this.HALF_BATHROOMS_INITIAL_NUMBER,
            })
            .subscribe((rooms: Room[]) => {
                this.storeDataService
                    .storeCurrentData({
                        rooms: rooms,
                    })
                    .subscribe(() => {
                        this.rooms = (rooms as Room[]).map((room) => ({ ...room }));
                        this.bedroomsObj = this.mapRooms(this.roomNames.BEDROOM);
                        this.fullBathroomsObj = this.mapRooms(this.roomNames.BATHROOM);
                        this.halfBathroomsObj = this.mapRooms(this.roomNames.HALF_BATH);
                        this.checkIsAnyRoomSelected();
                        this.checkIsDetailedMode();
                        this.getPricingQuote();
                    });
            });
    }

    checkIsDetailedMode() {
        this.isDetailedMode = this.allRooms.every((room) => room.isDetailed);
    }

    countDetailedRooms() {
        this.detailedRoomsCounter = this.allRooms.filter((room) => room.isDetailed).length;
    }

    mapOtherRooms() {
        return this.otherRooms.map((otherRoom) => ({
            ...ALL_ROOMS_OBJ[otherRoom.roomName],
            isSelected: otherRoom.isSelected,
            isDetailed: otherRoom.isDetailed,
            id: otherRoom.id,
        }));
    }

    mapRooms(roomName: RoomName) {
        return this.rooms
            .filter((room) => room.roomName.toLowerCase() === roomName)
            .map((room) => {
                return {
                    ...ALL_ROOMS_OBJ[room.roomName],
                    isDetailed: room.isDetailed,
                    isSelected: room.isSelected,
                    id: room.id,
                };
            });
    }

    isNameChanged(index: number) {
        return (
            index === this.allRooms.length - 1 ||
            this.allRooms[index].roomName !== this.allRooms[index + 1].roomName
        );
    }

    mapAppliances(appliances: Appliance[]) {
        const profileAppliances = this.storeDataService.profileData.appliances?.map(
            (appliance) => appliance.type
        );
        this.appliancesObj = appliances.map((appliance) => ({
            ...APPLIANCES_OBJ[appliance.type],
            type: appliance.type,
            price: Math.ceil(appliance.price),
            isSelected: !!profileAppliances?.includes(appliance.type),
        }));
    }

    checkIsAnyRoomSelected() {
        this.isAnyRoomSelected =
            this.rooms.some(
                (room) =>
                    room.isSelected && room.roomName !== 'Hallway' && room.roomName !== 'Stairs'
            ) || this.otherRooms.some((room) => room.isSelected);
        this.handleNavigation();
    }

    toggleDetailedMode(isDetailed: boolean) {
        const allRooms = [
            ...this.rooms,
            ...this.otherRooms,
            ...this.otherRoomsObj,
            ...this.bedroomsObj,
            ...this.fullBathroomsObj,
            ...this.halfBathroomsObj,
        ];
        allRooms.forEach((room) => (room.isDetailed = isDetailed));
        this.isDetailedMode = isDetailed;
        this.countDetailedRooms();
        this.setPriceByFrequency();
    }

    isAllAppliancesSelected(): boolean {
        return this.appliancesObj?.every((appliance: ApplianceObj) => appliance.isSelected);
    }

    toggleAllAppliancesSelection(event: Event) {
        const target = event.target as HTMLInputElement;
        const isChecked = target.checked;
        this.appliancesObj.forEach((appliance: ApplianceObj) => (appliance.isSelected = isChecked));
        this.getPricingQuote();
    }

    toggleRoomDetailed(room: RoomObj) {
        room.isDetailed = !room.isDetailed;
        this.updateDetailedRoom(room.id as string, room.isDetailed);
        this.countDetailedRooms();
    }

    toggleApplianceSelection(appliance: ApplianceObj, event?: Event) {
        if (event) {
            const target = event.target as HTMLInputElement;
            appliance.isSelected = target.checked;
        } else {
            appliance.isSelected = !appliance.isSelected;
        }
        this.updateAllAppliancesCheckbox();
        this.getPricingQuote();
    }

    toggleRoomSelection(room: RoomObj): void {
        room.isSelected = !room.isSelected;
        if (!room.isSelected && !this.isDetailedMode) {
            room.isDetailed = false;
        }
        this.updateSelectedRoom(room.id as string, room.isSelected);
        this.checkIsAnyRoomSelected();
        this.handleNavigation();
        this.getPricingQuote();
    }

    addRoom(roomName: RoomName) {
        this.pricingQuoteService.addRoomType(roomName).subscribe((newRoom) => {
            newRoom.isSelected = true;
            this.updateSelectedRoom(newRoom.id as string, newRoom.isSelected);
            this.rooms.push(newRoom);
            switch (roomName) {
                case this.roomNames.BEDROOM:
                    this.bedroomsObj = this.mapRooms(roomName);
                    break;
                case this.roomNames.BATHROOM:
                    this.fullBathroomsObj = this.mapRooms(roomName);
                    break;
                case this.roomNames.HALF_BATH:
                    this.halfBathroomsObj = this.mapRooms(roomName);
                    break;
            }
            this.checkIsAnyRoomSelected();
            this.getPricingQuote();
        });
    }

    updateSelectedRoom(id: string, checked: boolean) {
        const foundInRooms = this.rooms.find((room) => room.id === id);
        if (foundInRooms) {
            foundInRooms.isSelected = checked;
            if (!checked) {
                foundInRooms.isDetailed = false;
                this.countDetailedRooms();
            }
            return;
        }
        const foundInOtherRooms = this.otherRooms.find((room) => room.id === id);
        if (foundInOtherRooms) {
            foundInOtherRooms.isSelected = checked;
            if (!checked) {
                foundInOtherRooms.isDetailed = false;
                this.countDetailedRooms();
            }
            return;
        }
    }

    updateDetailedRoom(id: string, isDetailed: boolean) {
        const foundInRooms = this.rooms.find((room) => room.id === id);
        if (foundInRooms) {
            foundInRooms.isDetailed = isDetailed;
            return;
        }
        const foundInOtherRooms = this.otherRooms.find((room) => room.id === id);
        if (foundInOtherRooms) {
            foundInOtherRooms.isDetailed = isDetailed;
            return;
        }
    }

    updateAllAppliancesCheckbox() {
        const allAppliancesCheckbox = document.getElementById(
            'allAppliancesCheckbox'
        ) as HTMLInputElement;
        allAppliancesCheckbox.checked = this.isAllAppliancesSelected();
    }

    getPricingQuote() {
        const selectedRooms = [
            ...this.rooms?.filter((room) => room.isSelected),
            ...this.otherRooms?.filter((room) => room.isSelected),
        ];
        this.selectedAppliances = [
            ...this.appliancesObj
                .filter((appliance) => appliance.isSelected)
                .map((appliance) => ({
                    type: appliance.type,
                    quantity: appliance.quantity,
                })),
        ];
        const { bodyRequest, valid } = this.storeDataService.getProfileDataForQuote(
            selectedRooms,
            this.selectedAppliances
        );

        if (valid) {
            this.pricingQuoteService.getPricingQuote(bodyRequest).subscribe((price) => {
                this.price = price;
                this.setPriceByFrequency();
            });
        }
    }

    onFrequencySelected(value: number | string) {
        this.selectedFrequencyValue = value as string;
        if (this.price) {
            this.setPriceByFrequency();
        }
        this.handleNavigation();
    }
    onOccasionSelected(value: number | string) {
        this.selectedOccasionValue = value as string;
        this.handleNavigation();
    }

    setPriceByFrequency() {
        this.isOneTimeClean = false;
        switch (this.selectedFrequencyValue) {
            case CLEANING_FREQUENCY.ONE_TIME:
                this.isOneTimeClean = true;
                this.firstTimePrice = Math.ceil(this.price.oneTimeStandardClean as number);
                break;
            case CLEANING_FREQUENCY.WEEKLY:
                this.recurringPrice = Math.ceil(this.price.standardCleanWeekly as number);
                this.firstTimePrice = Math.ceil(this.price.firstCleanWeekly as number);
                break;
            case CLEANING_FREQUENCY.BI_WEEKLY:
                this.recurringPrice = Math.ceil(this.price.standardCleanBiWeekly as number);
                this.firstTimePrice = Math.ceil(this.price.firstCleanBiWeekly as number);
                break;
            case CLEANING_FREQUENCY.MONTHLY:
                this.recurringPrice = Math.ceil(this.price.standardCleanMonthly as number);
                this.firstTimePrice = Math.ceil(this.price.firstCleanMonthly as number);
                break;
        }
        if (this.isDetailedMode) {
            this.firstTimePrice = Math.ceil(this.price.oneTimeDetailedClean as number);
        }
    }

    handleNavigation() {
        if (
            this.isAnyRoomSelected &&
            ((this.selectedFrequencyValue === this.cleaningFrequency.ONE_TIME &&
                this.selectedOccasionValue) ||
                this.selectedFrequencyValue !== this.cleaningFrequency.ONE_TIME)
        ) {
            this.isNextButtonDisabled = false;
            this.isNextButtonActive = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
            this.navigationService.setNextButtonActive(this.isNextButtonActive);
        } else {
            this.isNextButtonDisabled = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
        }
    }

    activateNavButtons(active: boolean) {
        this.isNextButtonDisabled = !active;
        this.isNextButtonActive = active;
        this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
        this.navigationService.setNextButtonActive(this.isNextButtonActive);
    }

    storeCurrentData(button: string) {
        // TODO add this.googleAnalyticsService.event('recurring', 'frequency', item.gaTitle); or this.googleAnalyticsService.event('one-time', 'frequency', item.gaTitle);
        const isBackButton = button === BUTTON_BACK;
        if (!isBackButton) {
            this.storeDataService
                .storeCurrentData({
                    otherRooms: this.otherRooms,
                    rooms: this.rooms,
                    cleaningOccasion: this.selectedOccasionValue,
                    cleaningFrequency: this.selectedFrequencyValue,
                    url: this.navigationRoutes.nextRoute,
                    appliances: this.selectedAppliances,
                    subscriptionType: this.subscriptionType,
                })
                .subscribe(() => {
                    this.navigationService.setNavigationButtonClicked({
                        button: button,
                        allowNavigation: true,
                    });
                });
        } else {
            this.storeDataService
                .storeCurrentData({
                    url: this.navigationRoutes.nextRoute,
                })
                .subscribe(() => {
                    this.navigationService.setNavigationButtonClicked({
                        button: button,
                        allowNavigation: true,
                    });
                });
        }
    }

    override ngOnDestroy(): void {
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }
}
